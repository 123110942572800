import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Input } from 'reactstrap';

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 30px;
    margin-left: 40px;

    @media (max-width: 768px) {
        margin-top: 70px;
    }
`;

export const TitleBody = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #CD000E;
    margin-left: 10px;
    margin-top: 3px;
    height: 50px;
    width: 200px;
`;

export const DivMultimidia = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 10;
    height: 320px;

    @media (max-width: 768px) {
        gap: 30px;
    }
`;

export const QuatroFotos = styled(Link)`
    width: 100%;
    height: 302px;
    display: grid;
`;

export const Cima = styled.div`
    display: flex;
    width: 100%;
    height: 151px;
`;

export const Um = styled.div`
    max-width: 50%;
    min-width: 50%;
`;

export const Dois = styled.div`
    max-width: 50%;
    min-width: 50%;
`;

export const DivGrid = styled.div`
    display: grid;
    height: 50px;
    position: absolute;
    z-index: 999999;
    margin-right: -12px;
`;

export const DivBusca = styled.div`
    display: flex;
    height: 48px;
    width: 100%;
    justify-content: end;
`;

export const AreaBusca = styled.div`
    display: flex;
    background-color: #ffffff;
    width: 415px;
    padding: 10px 30px;
`;

export const CustomInputText = styled(Input)`
    border: 0px;
    width: 90%;
    height: 30px;
    background: #00000000;
    color: #7BC7FF;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        background: var(--primary);
        color: #7BC7FF;
        border: 0px;
    }

    &::-webkit-input-placeholder {
        color: #7BC7FF;
    }
`;

export const DivLink = styled(Link)`
    width: 80px;
    height: 100%;
`;

export const ButtonSearch = styled.button`
    background-color: #780C0F;
    color: #ffffff;
    border-radius: 15px;
    border: 0px;
    width: 100%;
    height: 100%;
    font-size: 12px;

    &:hover {
        background-color: #EDE253;
        color: #132448;
    }
`;

export const DivSearch2 = styled.div`
    height: 30px;
    border-radius: 20px;
    display: flex;
    border: 1px solid #780C0F;
`;

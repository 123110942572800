import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import * as S from './styles';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';
import LocalAmarelo from '../../assets/svg/LocalAmarelo';
import FaceAmarelo from '../../assets/svg/InstaAmarelo';
// import TwitterAmarelo from '../../assets/svg/TwitterAmarelo';
import InstaAmarelo from '../../assets/svg/FaceAmarelo';
import WhatsAmarelo from '../../assets/svg/WhatsAmarelo';

const Contato = () => {
  // const googleContato = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55267.99692988657!2d-51.30407712926206!3d-30.02969032795338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9519790e4d043eab%3A0x769a0820bae18fd4!2sCentro%20Hist%C3%B3rico%2C%20Porto%20Alegre%20-%20RS!5e0!3m2!1spt-BR!2sbr!4v1711456910223!5m2!1spt-BR!2sbr";

  return (
    <>
      <Header min="true" name="Documentos"/>
      <Container style={{ marginTop: "-200px", maxWidth: "94%", backgroundColor: "#FFFFFF", zIndex: "10000", position: "relative" }}>
        <Row>
          <Col xl={6}>
            <S.DivTitulos>
              <DestaqueIcon />
              <S.TitleBody>CONTATO</S.TitleBody>
            </S.DivTitulos>
          </Col>
        </Row>
        <Row style={{ maxWidth: "94%", marginLeft: "3%" }}>
          <Col md={12} style={{ marginTop: "30px", marginBottom: "20px" }}>
            <S.DivFlex>
              <LocalAmarelo />
              <S.Text>Uma marcha pelas ruas, 985<br />so Centro de Porto Alegre</S.Text>
            </S.DivFlex>
          </Col>
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }}>
            <S.LinkSocial href="https://www.facebook.com/construcaosocialista" target="_blank" rel="noopener noreferrer">
              <S.DivFlex>
                <FaceAmarelo />
                <S.Text>facebook.com/construcaosocialista</S.Text>
              </S.DivFlex>
            </S.LinkSocial>
          </Col>
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }}>
            <S.LinkSocial href="#" target="_blank" rel="noopener noreferrer">
              <S.DivFlex>
                {/* <TwitterAmarelo /> */}
                <S.Text>socialistaconstrucaopsol@gmail.com</S.Text>
              </S.DivFlex>
            </S.LinkSocial>
          </Col>
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }} />
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }} />
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }}>
            <S.LinkSocial href="https://www.instagram.com/cs_socialista/" target="_blank" rel="noopener noreferrer">
              <S.DivFlex>
                <InstaAmarelo />
                <S.Text>instagram.com/cs_socialista/</S.Text>
              </S.DivFlex>
            </S.LinkSocial>
          </Col>
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }}>
            <S.LinkSocial href="https://wa.me/51996770228" target="_blank" rel="noopener noreferrer">
              <S.DivFlex>
                <WhatsAmarelo />
                <S.Text>(51) 99677-0228</S.Text>
              </S.DivFlex>
            </S.LinkSocial>
          </Col>
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }}>
          </Col>
          <Col md={3} style={{ marginTop: "30px", marginBottom: "20px" }}>
          </Col>
        </Row>
        {/* <S.Text
        style={{ marginLeft: "3%", marginTop: "50px" }}>Localização</S.Text>
        <S.DivMaps>
          <iframe
            src={googleContato}
            width="100%"
            height="755px"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="maps"
          />
        </S.DivMaps> */}
      </Container>
      <Footer />
      <Assinatura />
    </>
  );
};

export default Contato;
import React from 'react';

function InstaAzul(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.027" height="30.136" viewBox="0 0 29.027 30.136">
            <g id="Grupo_3171" data-name="Grupo 3171" transform="translate(-9.316 -8.925)">
                <path id="Caminho_59" data-name="Caminho 59" d="M67.15,11.64c3.876,0,4.335.016,5.865.088a7.779,7.779,0,0,1,2.7.519,4.5,4.5,0,0,1,1.669,1.127,4.724,4.724,0,0,1,1.085,1.733,8.629,8.629,0,0,1,.5,2.8c.07,1.59.084,2.066.084,6.089s-.014,4.5-.084,6.089a8.629,8.629,0,0,1-.5,2.8,4.707,4.707,0,0,1-1.085,1.732,4.505,4.505,0,0,1-1.669,1.128,7.779,7.779,0,0,1-2.7.519c-1.53.072-1.989.088-5.865.088s-4.335-.016-5.865-.088a7.779,7.779,0,0,1-2.7-.519,4.5,4.5,0,0,1-1.669-1.128,4.706,4.706,0,0,1-1.085-1.732,8.63,8.63,0,0,1-.5-2.8c-.07-1.59-.084-2.066-.084-6.089s.014-4.5.084-6.089a8.63,8.63,0,0,1,.5-2.8,4.724,4.724,0,0,1,1.085-1.733,4.5,4.5,0,0,1,1.669-1.127,7.779,7.779,0,0,1,2.7-.519c1.53-.072,1.989-.088,5.865-.088m0-2.715c-3.941,0-4.435.017-5.984.091a10.306,10.306,0,0,0-3.523.7,7.08,7.08,0,0,0-2.57,1.737A7.417,7.417,0,0,0,53.4,14.123a11.376,11.376,0,0,0-.675,3.657c-.071,1.607-.088,2.12-.088,6.213s.017,4.6.088,6.213a11.384,11.384,0,0,0,.675,3.657,7.429,7.429,0,0,0,1.674,2.669,7.1,7.1,0,0,0,2.57,1.737,10.306,10.306,0,0,0,3.523.7c1.549.074,2.043.091,5.984.091s4.435-.017,5.984-.091a10.306,10.306,0,0,0,3.523-.7,7.11,7.11,0,0,0,2.57-1.737A7.453,7.453,0,0,0,80.9,33.862a11.427,11.427,0,0,0,.673-3.657c.071-1.608.088-2.121.088-6.213s-.017-4.606-.088-6.213a11.42,11.42,0,0,0-.673-3.657,7.44,7.44,0,0,0-1.675-2.669,7.092,7.092,0,0,0-2.57-1.737,10.306,10.306,0,0,0-3.523-.7c-1.549-.074-2.042-.091-5.984-.091" transform="translate(-43.32)" fill="#780C0F"/>
                <path id="Caminho_60" data-name="Caminho 60" d="M67.15,14.084a9.732,9.732,0,0,0-9.544,9.909,9.55,9.55,0,1,0,19.087,0,9.732,9.732,0,0,0-9.544-9.909m0,16.34a6.436,6.436,0,1,1,6.194-6.431,6.316,6.316,0,0,1-6.194,6.431" transform="translate(-43.32)" fill="#780C0F"/>
                <path id="Caminho_61" data-name="Caminho 61" d="M69.532,13.869A1.227,1.227,0,1,1,68.305,12.6a1.251,1.251,0,0,1,1.227,1.273" transform="translate(-36.415 1.755)" fill="#780C0F"/>
            </g>
        </svg>
    )
}

export default InstaAzul;
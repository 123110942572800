import React from 'react';
import * as S from './styles';
import Face from '../../assets/svg/FaceHeader';
import Insta from '../../assets/svg/Insta';
import Twitter from '../../assets/svg/Twitter';
import Whats from '../../assets/svg/Whats';
import Logo from '../../assets/img/logo.png';

const Footer = () => {
    return (
        <>
            <S.Fundo>
                <S.DivCenter to="/">
                    <img src={Logo} alt="" style={{ marginTop: "-30px" }} />
                </S.DivCenter>
                <S.ContainerMenu>
                    <S.NavLink to="/" >HOME</S.NavLink>
                    <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                    <S.NavLink to="/documentos" >DOCUMENTOS</S.NavLink>
                    <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                    <S.NavLink to="/cultura" >CULTURA</S.NavLink>
                    <S.NavLink to="/galeria" >GALERIA DE FOTOS</S.NavLink>
                    {/* <S.NavLink to="/plataforma" >PLATAFORMA</S.NavLink> */}
                    <S.NavLink to="/links" >LINKS</S.NavLink>
                    <S.NavLink to="/contato" >CONTATO</S.NavLink>
                </S.ContainerMenu>
            </S.Fundo>
            <S.FundoMobile>
                <S.HeaderMenu>
                    <S.DivCenter to="/">
                        <img src={Logo} alt="" width={"212px"} height={"99px"} />
                    </S.DivCenter>
                </S.HeaderMenu>
                <S.BodyMenu>
                    <S.NavLink to="/" >HOME</S.NavLink>
                    <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                    <S.NavLink to="/documentos" >DOCUMENTOS</S.NavLink>
                    <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                    <S.NavLink to="/cultura" >CULTURA</S.NavLink>
                    <S.NavLink to="/galeria" >GALERIA DE FOTOS</S.NavLink>
                    {/* <S.NavLink to="/plataforma" >PLATAFORMA</S.NavLink> */}
                    <S.NavLink to="/links" >LINKS</S.NavLink>
                    <S.NavLink to="/contato" >CONTATO</S.NavLink>
                    {/* <S.Divider />
                    <S.Text>Siga o Sindicaixa nas redes sociais.</S.Text>
                    <S.RedeSociaisMobile style={{ marginTop: "10px" }}>
                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                            <Face />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                            <Insta />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/Sindicaixa`}>
                            <Twitter />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                            <Whats />
                        </a>
                    </S.RedeSociaisMobile> */}
                    {/* <S.Contato>
                        <S.DivTelefone>
                            <S.CustomTelefone />
                            <S.TextContatoTelefone>
                                51 32248049
                            </S.TextContatoTelefone>
                        </S.DivTelefone>
                        <S.DivEmail>
                            <S.CustomEmail />
                            <S.TextContatoEmail>
                                sindicaixa@sindicaixars.org.br
                            </S.TextContatoEmail>
                        </S.DivEmail>
                    </S.Contato> */}
                </S.BodyMenu>
            </S.FundoMobile>
        </>
    );
};

export default Footer;
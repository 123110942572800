import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';
import DestaqueIconBranco from '../../assets/svg/DestaqueIconBranco';
// import LogoPq from '../../assets/img/logo_pq.png';
import Header from '../../components/Header';
import Destaque from '../../components/Destaque';
// import Newsletter from '../../components/Newsletter';
import Multimidia from '../../components/Multimidia';
// import Sedes from '../../components/Sedes';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import CardNoticias from '../../components/CardNoticias';
import Axios from '../../utils/Axios';
// import Parceiros from '../../components/Parceiros';
import Campanhas from '../../components/Campanhas';
// import Lupa from '../../assets/svg/Lupa';

const Home = () => {
  const [dados, setDados] = useState([]);
  const [dadosMultimidia, setDadosMultimidia] = useState([]);
  // const [search, setSearch] = useState([]);
  const largura = window.screen.width;

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/noticiasDestaque`).then((response) => {
      setDados(response.data)
    })
  }, []);

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/multimidias/home`).then((response) => {
      setDadosMultimidia(response.data)
    })
  }, []);

  return (
    <>
      <Header />
      {dados?.id !== undefined ?
        <Container style={{ maxWidth: "94%", marginBottom: "200px" }}>
          <Row>
            <Col xl={12}>
              <Destaque cartola={dados.cartola} titulo={dados.titulo} chamada={dados.chamada} picture={dados.picture} id={dados.id} />
            </Col>
          </Row>
        </Container>
      : ""
      }
      {dados?.length > 0 ?
        <Container style={{ maxWidth: "94%" , marginBottom: "35px" }}>
          <Row>
            <Col xl={12}>
              <S.DivTitulos>
                <DestaqueIcon />
                <S.TitleBody>Destaques</S.TitleBody>
              </S.DivTitulos>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <CardNoticias />
          </Row>
        </Container>
      :
        <Container style={{ marginTop: "-270px", maxWidth: "94%", backgroundColor: "#FFFFFF", zIndex: "10000", position: "relative" }}>
          <Row>
            <Col xl={6}>
              <S.DivTitulos style={{ marginLeft: "0px", marginBottom: "-25px" }}>
                <DestaqueIcon />
                <S.TitleBody>Destaques</S.TitleBody>
              </S.DivTitulos>
            </Col>
            {/* <Col xl={6} style={{ display: "flex", justifyContent: "end", marginTop: "-48px" }}>
              <S.DivGrid>
                <S.DivBusca>
                  <S.AreaBusca>
                    <S.DivSearch2>
                      <Lupa style={{ marginTop: "5px"}} />
                      <S.CustomInputText
                          id="search"
                          type="text"
                          name="search"
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                      />
                      <S.DivLink to={"/noticias?busca="+search}>
                          <S.ButtonSearch>Buscar</S.ButtonSearch>
                      </S.DivLink>
                    </S.DivSearch2>
                  </S.AreaBusca>
                </S.DivBusca>
              </S.DivGrid>
            </Col> */}
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <CardNoticias />
          </Row>
        </Container>
      } 
      <Container style={{ maxWidth: "100%", backgroundColor: "#CD000E", marginTop: "35px"  }}>
        <Row>
          <Col xl={12}>
            <S.DivTitulos>
                <DestaqueIconBranco />
                <S.TitleBody style={{ color: "#ffffff" }}>TV Socialista</S.TitleBody>
              </S.DivTitulos>
          </Col>
        </Row>
        <Row style={{ maxWidth: "96%", marginTop: "35px", marginLeft: "2%", paddingBottom: "35px" }}>
          {dadosMultimidia !== undefined && dadosMultimidia.map((item, index) => (
            parseInt(item.modelo) === 1 ?
              largura > "1200" && index > 2 ?
                  ""
                :
                  <Col xl={4} md={6} key={index}>
                    <S.DivMultimidia>
                      <Multimidia height={"302px"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={item.urlYoutube} />
                    </S.DivMultimidia>
                  </Col>
            :
            ""
            // <Col xl={4} md={6} key={index}>
            //   <S.DivMultimidia>
            //     {item.imagem0 && item.imagem1 && item.imagem2 && item.imagem3 !== null ?
            //       <S.QuatroFotos to={"/multimidia/detalhes/"+item.id}>
            //         <S.Cima>
            //           <S.Um>
            //             <img src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem0}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
            //           </S.Um>
            //           <S.Dois>
            //             <img src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem1}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
            //           </S.Dois>
            //         </S.Cima>
            //         <S.Cima>
            //           <S.Um>
            //             <img src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem2}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
            //           </S.Um>
            //           <S.Dois>
            //             <img src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem3}`} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
            //           </S.Dois>
            //         </S.Cima>
            //       </S.QuatroFotos>
            //     :
            //       <S.DivMultimidia>
            //         {item.imagem0 ?
            //           <img 
            //             src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem0}`} 
            //             alt="" 
            //             style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
            //           />
            //         : item.imagem1 ?
            //           <img 
            //             src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem1}`} 
            //             alt="" 
            //             style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
            //           />
            //         : item.imagem2 ?
            //           <img 
            //             src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem2}`} 
            //             alt="" 
            //             style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
            //           />
            //         : item.imagem3 ?
            //           <img 
            //             src={`https://construcaosocialista.com.br/api/storage/app/public/${item.imagem3}`} 
            //             alt="" 
            //             style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
            //           />
            //         : ""
            //        }
            //       </S.DivMultimidia>
            //     }
            //   </S.DivMultimidia>
            // </Col>
          ))}
        </Row>
        {/* <Row>
          <Col xl={12}>
            <S.DivTitulos>
              <img src={LogoPq} alt="" />
              <S.TitleBody>Sedes</S.TitleBody>
            </S.DivTitulos>
          </Col>
        </Row>  */}
      </Container>
      {/* <Parceiros />*/}
      <Campanhas /> 
      <Footer />
      <Assinatura />
    </>
  );
};

export default Home; 
import React, { useState, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import Axios from '../../utils/Axios';
import * as S from './styles';
// import Multimidia from '../../components/Multimidia';
// import FaceCinza from '../../assets/svg/FaceCinza';
// import TwitterCinza from '../../assets/svg/TwitterCinza';
// import WhatsCinza from '../../assets/svg/WhatsCinza';
import { Col } from 'reactstrap';
import Audio from '../../components/Audio';
// import Face from '../../assets/svg/Face';
// import Twitter from '../../assets/svg/Twitter';
// import Whats from '../../assets/svg/Whats';
// import FaceAzul from '../../assets/svg/FaceAzul';
// import TwitterAzul from '../../assets/svg/TwitterAzul';
// import WhatsAzul from '../../assets/svg/WhatsAzul';
import Loading from '../../components/Loading';
import MaiorPag from '../../assets/svg/Maior';
import MenorPag from '../../assets/svg/Menor';
import ModalFull from '../../components/ModalFull';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';

const Noticias = () => {
  const [dados, setDados] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const buscaGet = urlParams.get('busca');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [face, setFace] = useState("");
  const [insta, setInsta] = useState("");

  //paginacao
  const [itemsPorPage] = useState(8);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const pages = Math.ceil(dados.length / itemsPorPage);
  const startIndex = paginaAtual * itemsPorPage;
  const endIndex = startIndex + itemsPorPage;
  const currentItens = dados.slice(startIndex, endIndex);

  useEffect( () => {
    setLoading(true);
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    buscaGet === null ?
        Axios.get(`/noticiasPage`).then((response) => {
        setDados(response.data);
        setLoading(false);
        })
    :
        Axios.get(`/busca/${buscaGet}`).then((response) => {
        setDados(response.data);
        setLoading(false);
        })
  }, [buscaGet]);

  return (
    <>
      <Header min="true" name="Notícias" />
      <Container style={{ marginTop: "-200px", maxWidth: "94%", backgroundColor: "#FFFFFF", zIndex: "10000", position: "relative" }}>
      {modal && 
        <ModalFull
            modal={modal} setModal={setModal} 
            acao="news" 
            face={face}
            insta={insta}
        />
      }
      {loading ? <Loading /> :
        <>
            <Row>
              <Col xl={6}>
                <S.DivTitulos>
                  <DestaqueIcon />
                  <S.TitleBody>NOTÍCIAS</S.TitleBody>
                </S.DivTitulos>
              </Col>
            </Row>
            <Row style={{ marginTop: "60px" }}>
            {dados.length > 0 ?
                currentItens !== undefined && currentItens.map((item, index) => (
                  item.modelo === "2" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            {item.insta !== "undefined" && item.face !== "undefined" ?
                                <S.DivModal onClick={() => {setModal(!modal); setFace(item.face); setInsta(item.insta) }}>
                                    <S.Imagem picture={item.picture} color={item.color} />
                                </S.DivModal>
                            : item.insta !== "undefined" ?
                                <a target="_blank" rel="noreferrer" href={item.insta}>
                                    <S.Imagem picture={item.picture} color={item.color} />
                                </a>
                            : item.face !== "undefined" ?
                                <a target="_blank" rel="noreferrer" href={item.face}>
                                    <S.Imagem picture={item.picture} color={item.color} />
                                </a>
                            :
                                ""
                            }
                        </S.DivCard>
                    </Col>
                  : item.modelo === "3" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            <S.Duplo>
                                {/* <S.DivMultimidia>
                                    <Multimidia height={"100%"} width={"100%"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={item.urlYoutube} />
                                </S.DivMultimidia> */}
                                <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                    <S.FundoDuplo cor="white">
                                        <S.TextosDuplo>
                                            <S.Lapela>
                                                {/* {item.cartola} */}
                                            </S.Lapela>
                                            <S.TitleDuplo cor="white">{item.titulo}</S.TitleDuplo>
                                            <S.TextDuplo cor="white">{item.chamada}.</S.TextDuplo>
                                        </S.TextosDuplo>
                                        <S.BotoesDuplo>
                                            {/* <S.Divider />
                                            <S.RedeSociais>
                                                <S.Text2 cor="white">Compartilhar</S.Text2>
                                                <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                    <FaceCinza width="30px" height="30px" />
                                                </a>
                                                <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                    <TwitterCinza width="30px" height="30px" />
                                                </a>
                                                <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                                    <WhatsCinza width="30px" height="30px" />
                                                </a>
                                            </S.RedeSociais> */}
                                        </S.BotoesDuplo>
                                    </S.FundoDuplo>
                                </S.DivLink>
                            </S.Duplo>
                        </S.DivCard>
                    </Col>
                  : item.modelo === "4" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            <S.Duplo>
                                {/* <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                    <S.ImagemDuplo picture={item.picture}/>
                                </S.DivLink> */}
                                <S.FundoDuplo cor={item.colorDuplo}>
                                    <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                        <S.TextosDuplo>
                                            <S.Lapela>
                                                {/* {item.cartola} */}
                                            </S.Lapela>
                                            <S.TitleDuplo cor={item.colorDuplo} tamanho={item.titulo.length}>{item.titulo}</S.TitleDuplo>
                                            <S.TextDuplo cor={item.colorDuplo}>{item.chamada}</S.TextDuplo>
                                        </S.TextosDuplo>
                                    </S.DivLink>
                                    <S.BotoesDuplo>
                                        {/* <S.Divider />
                                        <S.RedeSociais>
                                            <S.Text2 cor="white">Compartilhar</S.Text2>
                                            <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                <FaceCinza width="30px" height="30px" />
                                            </a>
                                            <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                                <TwitterCinza width="30px" height="30px" />
                                            </a>
                                            <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                                <WhatsCinza width="30px" height="30px" />
                                            </a>
                                        </S.RedeSociais> */}
                                    </S.BotoesDuplo>
                                </S.FundoDuplo>
                            </S.Duplo>
                        </S.DivCard>
                    </Col>
                  : item.modelo === "5" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.Fundo cor="secundary" to={"/noticias/detalhes/"+item.id}>
                            <S.Lapela cor="white">
                                {/* {item.cartola} */}
                            </S.Lapela>
                            <Audio size="medio" cor="azul_claro" />
                            <S.TextPq cor="primary" style={{ marginTop: "-170px" }}>{item.chamada}</S.TextPq>
                            <S.BotoesAudio>
                                {/* <S.Divider cor="primary" />
                                <S.RedeSociais>
                                    <S.Text2 cor="primary">Compartilhar</S.Text2>
                                    <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                        <FaceAzul width="30px" height="30px" />
                                    </a>
                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                        <TwitterAzul width="30px" height="30px" />
                                    </a>
                                    <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                        <WhatsAzul width="30px" height="30px" />
                                    </a>
                                </S.RedeSociais> */}
                            </S.BotoesAudio>
                        </S.Fundo>
                    </Col>
                  : item.modelo === "6" || item.modelo === "1" ?
                    <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                        <S.DivCard>
                            <S.Fundo>
                                <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                    <S.Textos>
                                        <S.Lapela>
                                            {/* {item.cartola} */}
                                        </S.Lapela>
                                        <S.Title>{item.titulo}</S.Title>
                                        {item.titulo.length > 80 ? "" : <S.Text>{item.chamada}</S.Text>}
                                    </S.Textos>
                                </S.DivLink>
                                <S.Botoes>
                                    {/* <S.Divider />
                                    <S.RedeSociais>
                                        <S.Text2>Compartilhar</S.Text2>
                                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                            <Face width="30px" height="30px" />
                                        </a>
                                        <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                            <Twitter width="30px" height="30px" />
                                        </a>
                                        <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                            <Whats width="30px" height="30px" />
                                        </a>
                                    </S.RedeSociais> */}
                                </S.Botoes>
                            </S.Fundo>
                        </S.DivCard>
                    </Col>
                  : ""
              ))
              :
                <S.DivMensagem>
                    <S.Title cor="blue">Não foi encontrado nenhum sindicalizado!</S.Title>
                </S.DivMensagem>
              }
            </Row>
        </>
      }  
      <Row>
          {currentItens.length > 0 ?
            <S.DivPagination>
              <S.Paginacao disabled={paginaAtual === 0 ? true : false} onClick={(e) => setPaginaAtual(Number(paginaAtual-1))}><MenorPag style={{ width: "25px", height: "25px", marginLeft: "-3px" }} /></S.Paginacao>
              {Array.from(Array(pages), (item, index) => {
                if(paginaAtual === index){
                  return <S.PaginacaoAtivo value={index} onClick={(e) => setPaginaAtual(Number(e.target.value))}>{index + 1}</S.PaginacaoAtivo>
                } else {
                  return <S.Paginacao value={index} onClick={(e) => setPaginaAtual(Number(e.target.value))}>{index + 1}</S.Paginacao>
                }
              })}
              <S.Paginacao disabled={paginaAtual === pages-1 ? true : false } onClick={() => setPaginaAtual(Number(paginaAtual+1))}><MaiorPag style={{ width: "25px", height: "25px", marginLeft: "0px" }} /></S.Paginacao>
            </S.DivPagination>
            :
            ""
          }
        </Row>
        </Container>

      <Footer />
      <Assinatura />
    </>
  );
};

export default Noticias;
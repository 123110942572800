import React from 'react';

function InstaHader(props) {
    return (
        <svg id="Grupo_3187" data-name="Grupo 3187" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.617" height="23.511" viewBox="0 0 23.617 23.511" fill="none" {...props}>
            <defs>
                <clipPath id="clip-path">
                <rect id="Retângulo_3814" data-name="Retângulo 3814" width="23.617" height="23.511" fill="#ede253"/>
                </clipPath>
            </defs>
            <g id="Grupo_3182" data-name="Grupo 3182" transform="translate(0 0)" clipPath="url(#clip-path)">
                <path id="Caminho_507" data-name="Caminho 507" d="M27.1,17.985H22.329A3.834,3.834,0,0,0,18.5,21.819v4.775a3.834,3.834,0,0,0,3.834,3.833H27.1a3.833,3.833,0,0,0,3.834-3.833V21.819A3.834,3.834,0,0,0,27.1,17.985m2.639,8.151a3.1,3.1,0,0,1-3.1,3.1H22.787a3.1,3.1,0,0,1-3.1-3.1V22.277a3.1,3.1,0,0,1,3.1-3.1h3.859a3.1,3.1,0,0,1,3.1,3.1Z" transform="translate(-12.792 -12.44)" fill="#ede253"/>
                <path id="Caminho_508" data-name="Caminho 508" d="M31.861,27.6a3.277,3.277,0,1,0,3.277,3.277A3.277,3.277,0,0,0,31.861,27.6m0,5.249a1.972,1.972,0,1,1,1.972-1.972,1.972,1.972,0,0,1-1.972,1.972" transform="translate(-19.77 -19.093)" fill="#ede253"/>
                <path id="Caminho_509" data-name="Caminho 509" d="M47.237,24.733a.834.834,0,1,0,.834.834.834.834,0,0,0-.834-.834" transform="translate(-32.096 -17.107)" fill="#ede253"/>
                <path id="Caminho_510" data-name="Caminho 510" d="M62.311,61.826c-.323.1-.5.456-.656.734-.139.253-.407.55-.408.845a.153.153,0,0,0,.192.145c.286-.081.5-.415.707-.615.228-.222.534-.493.539-.827a.3.3,0,0,0-.375-.282" transform="translate(-42.363 -42.754)" fill="#ede253"/>
                <path id="Caminho_511" data-name="Caminho 511" d="M23.406,12.594c1.4-5.656-4.443-11.375-9.76-12.381a11.543,11.543,0,0,0-8.455,1.6C3.051,3.2.862,5.679.592,8.3a.217.217,0,0,0,.038.145c-1.686,4.327.151,9.767,3.849,12.511,3.667,2.722,9.694,3.677,13.593.833a.316.316,0,0,0-.322-.544l-.424.195a.292.292,0,0,0-.4-.115c-2.6,1.231-6.2.609-8.766-.306-.289-.1-.489.359-.211.494a11.421,11.421,0,0,0,4.374,1.133,12.089,12.089,0,0,0,1.907-.056,9.008,9.008,0,0,1-3.044.258,12.576,12.576,0,0,1-5.924-2.219,9.618,9.618,0,0,1-4.249-5.606,11.5,11.5,0,0,1,.031-6.5.228.228,0,0,0,.072-.08c.115-.216.226-.428.338-.64A10.193,10.193,0,0,0,.9,13.1c.553,3.357,2.444,5.576,5.23,7.4.334.219.669-.343.314-.531A10.178,10.178,0,0,1,1.6,12.951a10.965,10.965,0,0,1,2.427-8.23c.153-.2-.171-.428-.346-.264a8.6,8.6,0,0,0-.816.882A10.678,10.678,0,0,1,4.284,3.6c-.125.211.076.532.33.368.651-.417,1.279-.861,1.954-1.241.481-.271.993-.488,1.487-.735a.5.5,0,0,0,.279,0c3.582-.91,7.149-.517,9.941,2.1a.2.2,0,0,0,.307-.036.224.224,0,0,0,.219.282.233.233,0,0,0,0-.465.211.211,0,0,0-.181.091.191.191,0,0,0-.056-.159,10.734,10.734,0,0,0-3.4-2.378C18.945,2.817,22.083,6.2,22.535,10.1a10,10,0,0,0-1.564-3.54.265.265,0,0,0-.459.265,13.222,13.222,0,0,1,1.561,5.988,10.122,10.122,0,0,1-1.951,5.881c-.119.184.156.409.307.257a.2.2,0,0,0,.232-.032,8.183,8.183,0,0,0,2.754-6.287.315.315,0,0,0-.009-.035" transform="translate(0 0)" fill="#ede253"/>
                <path id="Caminho_512" data-name="Caminho 512" d="M22.721,66.969a.3.3,0,0,0,.2.405,1.818,1.818,0,0,0,.374.1c.228.028.313-.282.177-.423a1.851,1.851,0,0,0-.318-.229.3.3,0,0,0-.431.143" transform="translate(-15.698 -46.19)" fill="#ede253"/>
            </g>
        </svg>
    )
}

export default InstaHader;
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import Axios from '../../utils/Axios';
import * as S from './styles';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';
import ButtonPrimary from '../../components/ButtonPrimary';
import Loading from '../../components/Loading';

const Documentos = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect( () => {
    setLoading(true);
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/documentos`).then((response) => {
      setDados(response.data)
      setLoading(false);
    })
  }, []);

  return (
    <>
      <Header min="true" name="Documentos"/>
      <Container style={{ marginTop: "-200px", maxWidth: "94%", backgroundColor: "#FFFFFF", zIndex: "10000", position: "relative" }}>
        {loading ? <Loading /> :
          <>
            <Row>
              <Col xl={6}>
                <S.DivTitulos>
                  <DestaqueIcon />
                  <S.TitleBody>DOCUMENTOS</S.TitleBody>
                </S.DivTitulos>
              </Col>
              {/* <Col xl={6} style={{ display: "flex", justifyContent: "end", marginTop: "-48px" }}>
                <S.DivGrid>
                  <S.DivBusca>
                    <S.AreaBusca>
                      <S.DivSearch2>
                        <Lupa style={{ marginTop: "5px"}} />
                        <S.CustomInputText
                            id="search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                        <S.DivLink to={"/noticias?busca="+search}>
                            <S.ButtonSearch>Buscar</S.ButtonSearch>
                        </S.DivLink>
                      </S.DivSearch2>
                    </S.AreaBusca>
                  </S.DivBusca>
                </S.DivGrid>
              </Col> */}
            </Row>
            <Row style={{ maxWidth: "94%", marginLeft: "3%" }}>
              {dados !== undefined && dados.map((item, index) => (
                <Col md={4} key={index} style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <S.DivFlex>
                    <S.CustomDocumentosIcon />
                    <S.DivGrid>
                      <S.Title>{item.titulo}</S.Title>
                      <S.Text>{item.texto}</S.Text>
                      <a target="_blank" rel="noreferrer" href={`https://construcaosocialista.com.br/api/storage/app/public/${item.arquivo}`} style={{ textDecoration: "none" }}>
                        <ButtonPrimary label="BAIXAR" size="95%" cor="#CD000E" />
                      </a>
                    </S.DivGrid>
                  </S.DivFlex>
                </Col>
              ))}
            </Row>
          </>
        }
      </Container>
      <Footer />
      <Assinatura />
    </>
  );
};

export default Documentos;
import styled from 'styled-components';

export const CustonButtonPrimary = styled.button`
    background-color: ${({ cor }) => { if (cor === "secundary") { return '#EDE253'; } else { return '#CD000E'; }}};
    color: #FFFFFF;
    border-radius: ${({ efeito }) => { if (efeito === "true") { return '15px 0 15px 0'; } else { return '15px'; }}};
    border: 0px;

    &:hover {
        background-color: #F7DF1C;
        color: #CD000E;
    }
`;

export const TextButton = styled.p`
    font-size: 15px;
    margin-top: 6px;
`;
import React  from 'react';
import * as S from './styled';
import FaceLinks from '../../assets/svg/FaceAmarelo';
import InstaLinks from '../../assets/svg/InstaAmarelo';
import TwitterLinks from '../../assets/svg/TwitterAmarelo';
import WhatsLinks from '../../assets/svg/WhatsAmarelo';
import SiteLinks from '../../assets/svg/SiteAmarelo';

const CardLinks = (props) => {

    return (
        <S.Container>
            <S.Juntar>
                <S.Title>{props.titulo}</S.Title>
                <S.Text>{props.texto !== "undefined" ? props.texto : ""}</S.Text>
            </S.Juntar>
            <S.RedeSocial>
                {props.face === null || props.face === "undefined" ? "" : <a target="_blank" rel="noreferrer" href={`${props.face}`}><InstaLinks /></a>}
                {props.insta === null || props.insta === "undefined" ? "" : <a target="_blank" rel="noreferrer" href={`${props.insta}`}><FaceLinks /></a>}
                {props.twitter === null || props.twitter === "undefined" ? "" : <a target="_blank" rel="noreferrer" href={`${props.twitter}`}><TwitterLinks /></a>}
                {props.whats === null || props.whats === "undefined" ? "" : <a target="_blank" rel="noreferrer" href={`${props.whats}`}><WhatsLinks /></a>}
                {props.site === null || props.site === "undefined" ? "" : <a target="_blank" rel="noreferrer" href={`${props.site}`}><SiteLinks /></a>}
            </S.RedeSocial>
        </S.Container> 
    )
}

export default CardLinks;
import styled from 'styled-components';
import DestaqueImg from '../../assets/img/destaque.png';
import MarcacaoImg from '../../assets/svg/Marcacao';
import { Link } from "react-router-dom";

export const DivDestaque = styled.div`
    width: 100%;
    max-height: 500px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 99;
    margin-top: -200px;
    border-radius: 30px;
    text-decoration: none;

    @media (max-width: 768px) {
        display: grid;
        margin-top: -200px;
    }
`;

export const ImgDestaque = styled.div`
    min-width: 50%;
    max-height: 100%;
    min-height: 100%;
    background-image: url(${(picture) => `http://sindicaixars.web227.uni5.net/api/storage/app/public/${picture.picture}`});
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 15px 0 0 15px;
    background-size: cover;
    background-color: #004070;
    z-index: 1;

    @media (max-width: 768px) {
        border-radius: 15px;
    }
`;

export const Imagem = styled.div`
    min-width: 70%;
    height: 500px;
    background-image: url(${DestaqueImg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #004070;
    position: relative;
    z-index: 1;
    border-radius: 15px 0 0 15px;

    @media (max-width: 768px) {
        border-radius: 15px;
        height: 200px;
    }
`;

export const Left = styled(Link)`
    min-width: 70%;
    height: 500px;
    border-radius: 15px 0 0 15px;
    justify-content: end;

    @media (max-width: 1200px) {
        min-width: 50%;
    }
    @media (max-width: 768px) {
        height: 200px;
    }
`;

export const DivMarcacao = styled.div`
    display: flex;
    justify-content: end;
`;

export const Marcacao = styled(MarcacaoImg)`
    position: relative; 
    z-index: 99;
    transform: rotate(90deg);
    margin-right: -10px;
    margin-top: -450px;
`;

export const Right = styled.div`
    padding: 50px 20px;
    background-color: #004070;
    border-radius: 0 15px 15px 0;
    display: grid;
    justify-content: space-between;

    @media (max-width: 768px) {
        margin-top: -30px;
        border-radius: 0 0 15px 15px;
    }
`;

export const Text = styled.p`
    font-size 15px;
    color: #FFFFFF;
    margin-top: -20px;

    @media (max-width: 1580px) {
        margin-top: 10px;
    }

    @media (max-width: 1300px) {
        font-size: 14px;
    }

    @media (max-width: 700px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.4; 
        max-height: 80px;     
        -webkit-line-clamp: 4; 
        -webkit-box-orient: vertical;
    }
    @media (max-width: 410px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.4; 
        max-height: 40px;     
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        margin-top: -15px;
    }
`;

export const TextCompartilhar = styled.p`
    font-size 15px;
    color: #7BC7FF;
`;

export const RedeSociais = styled.div`
    display: flex;
    justify-content: center;
    max-width: 400px;
    gap: 35px;
    align-items: center;
    position: relative;
    z-index: 4;

    @media (max-width: 1450px) {
        gap: 20px;
    }
    @media (max-width: 1300px) {
        gap: 15px;
    }
    @media (max-width: 1200px) {
        gap: 35px;
        margin-left: 40px;
    }
    @media (max-width: 1100px) {
        gap: 35px;
        margin-left: 20px;
    }
    @media (max-width: 1000px) {
        gap: 25px;
        margin-left: 5px;
    }
    @media (max-width: 900px) {
        gap: 15px;
        margin-left: 5px;
    }
`;

export const Title = styled.p`
    font-size: 35px;
    color: #FFFFFF;
    font-weight: bold;
    margin-top: -20px;
    line-height: 1.0;

    @media (max-width: 1580px) {
        margin-top: -10px;
    }
`;

export const Botoes = styled.div`
    display: grid;
    height: 80px;
    position:absolute;
    bottom: 20px;
    margin-top: 100px;
    width: 25%;

    @media (max-width: 1200px) {
        min-width: 45%;
    }

    @media (max-width: 768px) {
        min-width: 90%;
        margin-left: 20px;
    }
`;

export const Tag = styled.p`
    font-size: 15px;
    color: #7BC7FF;
    font-weight: bold;
`;

export const Textos = styled(Link)`
    display: grid;
    height: 250px;
    text-decoration: none;

    @media (max-width: 1200px) {
        height: 150px;
        margin-bottom: 70px;
    }
`;

export const Divider = styled.hr`
    border-top: 1px groove #7BC7FF;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    width: 100%;
    margin: 0;
`;


import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Input } from 'reactstrap';
import DocumentosIcon from '../../assets/svg/Documentos';

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 30px;
    margin-left: 40px;
`;

export const TitleBody = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #CD000E;
    margin-left: 10px;
    margin-top: 3px;
    height: 50px;
`;

export const TextBody = styled.p`
    font-size: 45px;
    font-weight: bold;
    color: #CD000E;
    text-align: center;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #CD000E;
`;

export const Text = styled.div`
    font-size: 15px;
    color: #CD000E;
`;

export const DivGrid = styled.div`
    display: grid;
    gap: 7px;
    width: 80%;
`;

export const DivBusca = styled.div`
    display: flex;
    height: 48px;
    width: 100%;
    justify-content: end;
`;

export const AreaBusca = styled.div`
    display: flex;
    background-color: #ffffff;
    width: 415px;
    padding: 10px 30px;
`;

export const DivSearch2 = styled.div`
    height: 30px;
    border-radius: 20px;
    display: flex;
    border: 1px solid #780C0F;
`;

export const CustomInputText = styled(Input)`
    border: 0px;
    width: 90%;
    height: 30px;
    background: #00000000;
    color: #7BC7FF;

    &:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
        background: var(--primary);
        color: #7BC7FF;
        border: 0px;
    }

    &::-webkit-input-placeholder {
        color: #7BC7FF;
    }
`;

export const DivLink = styled(Link)`
    width: 80px;
    height: 100%;
`;

export const ButtonSearch = styled.button`
    background-color: #780C0F;
    color: #ffffff;
    border-radius: 15px;
    border: 0px;
    width: 100%;
    height: 100%;
    font-size: 12px;

    &:hover {
        background-color: #EDE253;
        color: #132448;
    }
`;

export const DivFlex = styled.div`
    display: flex;
    gap: 10px;
`;

export const CustomDocumentosIcon = styled(DocumentosIcon)`
    min-width: 35px;
    min-height: 45px;
`;
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Merriweather', serif;
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Router basename="/">
        <Routes />
      </Router>
    </>
  );
}

export default App;
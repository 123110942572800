import styled from 'styled-components';

export const Fundo = styled.a`
    display: flex;
    justify-content: center;
    background-color: #CD000E;
    align-items: center;
    height: 80px;
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
        color: #FFFFFF;
    }
`;
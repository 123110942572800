import styled from 'styled-components';
import Face from '../../assets/svg/FaceHeader';
import Twitter from '../../assets/svg/Twitter';
import Whats from '../../assets/svg/Whats';
import { Link } from "react-router-dom";
import card2 from '../../assets/img/card2.png';

export const Fundo = styled.div`
    display: grid;
    background-color: ${({ cor }) => { if (cor === "secundary") { return '#7BC7FF'; } else { return '#CD000E'; }}};
    max-width: 100%;
    max-height: 400px;
    min-height: 400px;
    padding: 50px 20px;

    @media (max-width: 1200px) {
        max-height: 470px;
        min-height: 470px;
    }
`;

export const Lapela = styled.div`
    color: ${({ cor }) => { if (cor === "white") { return '#FFFFFF'; } else { return '#7BC7FF'; }}};
    font-size: 15px;
    font-weight: bold;
`;

export const Title = styled.div`
    color: #FFFFFF;
    font-size: 35px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: 1420px) {
        font-size: 30px;
    }
    @media (max-width: 1200px) {
        font-size: 35px;
    }
`;

export const TitleDuplo = styled.div`
    color: ${({ cor }) => { 
        if (cor === "primary") { 
            return '#FFFFFF' 
        } else if(cor === "tertiary") {
            return '#EDE253' 
        } else if(cor === "white") {
            return '#FFFFFF'
        } else { 
            return '#CD000E' 
        }}};
    font-size: 35px;
    margin-top: -10px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: 1200px) {
        margin-top: -30px;
        line-height: 1;
    }
    @media (max-width: 1000px) {
        font-size: 30px;
    }
    @media (max-width: 500px) {
        font-size: 27px;
    }
    @media (max-width: 400px) {
        font-size: 25px;
    }
`;

export const Text = styled.p`
    color: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else { return '#FFFFFF'; }}};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3; 
    max-height: 150px;     
    -webkit-line-clamp: 7; 
    -webkit-box-orient: vertical;

    @media (max-width: 1500px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.4; 
        max-height: 40px;     
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        margin-top: -5px;
    } 
    @media (max-width: 1200px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.4; 
        max-height: 80px;     
        -webkit-line-clamp: 4; 
        -webkit-box-orient: vertical;
        margin-top: -5px;
    }

    @media (max-width: 600px) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.4; 
        max-height: 80px;     
        -webkit-line-clamp: 4; 
        -webkit-box-orient: vertical;
        margin-top: 10px;
    }
`;


export const TextDuplo = styled.p`
    color: ${({ cor }) => { 
        if (cor === "primary") { 
            return '#FFFFFF' 
        } else if(cor === "tertiary") {
            return '#EDE253' 
        } else if(cor === "white") {
            return '#FFFFFF'
        } else { 
            return '#CD000E' 
        }}};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3; 
    max-height: 210px;     
    -webkit-line-clamp: 7; 
    -webkit-box-orient: vertical;
        
    @media (max-width: 1700px) {
        max-height: 138px;
    } 
    @media (max-width: 1200px) {
        -webkit-line-clamp: 0;
        max-height: 0px;  
    }
`;

export const Divider = styled.hr`
    border-top: ${({ cor }) => { if (cor === "primary") { return '1px groove #004070'; } else if(cor === "white") { return '1px groove #CBCBCB' } else { return '1px groove #7BC7FF'; }}};
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    width: 100%;
    margin: 24px 0;
`;

export const Botoes = styled.div`
    display: grid;
    height: 50px;
    bottom: 0;
    width: 100%;
    margin-top: -5px;

    @media (max-width: 1200px) {
        margin-top: 70px;
    } 
    @media (max-width: 600px) {
        margin-top: 30px;
    }
`;

export const BotoesDuplo = styled.div`
    display: grid;
    height: 50px;
    bottom: 0;
    width: 100%;
    margin-top: -5px;

    @media (max-width: 1200px) {
        margin-top: -80px;
    } 
`;

export const BotoesAudio = styled.div`
    display: grid;
    height: 50px;
    bottom: 0;
    width: 100%;
    margin-top: -105px;

    @media (max-width: 1200px) {
        margin-top: -35px;
    } 
`;

export const RedeSociais = styled.div`
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: 100%;

    @media (max-width: 1500px) {
        gap: 15px;
    } 
    @media (max-width: 1300px) {
        gap: 5px;
    } 
    @media (max-width: 1200px) {
        gap: 30px;
    } 
`;

export const Text2 = styled.div`
    color: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else if(cor === "white") { return '#CBCBCB' } else { return '#7BC7FF'; }}};
    font-size: 13px;
`;

export const CustomFace = styled(Face)`
    & path {
        fill: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else { return '#CD000E'; }}};
    }
`;

export const CustomTwitter = styled(Twitter)`
    & path {
        fill: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else { return '#CD000E'; }}};
    }
`;

export const CustomWhats = styled(Whats)`
    & path {
        fill: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else { return '#CD000E'; }}};
    }
`;

export const Duplo = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 1200px) {
        display: grid;
    } 
`;

export const FundoDuplo = styled.div`
    display: grid;
    background-color: ${({ cor }) => { if (cor === "secundary") { return '#FCFCFC'; } else if(cor === "primary") { return '#EDE253'; } else if(cor === "tertiary"){ return '#CD000E'} else { return 'var(primary)'; }}};
    max-width: 100%;
    max-height: 400px;
    min-height: 400px;
    padding: 50px 20px;

    @media (max-width: 1200px) {
        padding: 20px 20px;
        max-height: 220px;
        min-height: 220px;
    }
`;

export const DivCard = styled.div`
    text-decoration: none;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
`;

export const DivLink = styled(Link)`
    text-decoration: none;
    min-width: 50%;
    min-height: 50%;
`;

export const ImagemDuplo = styled.div`
    min-width: 50%;
    max-height: 400px;
    min-height: 400px;
    background-image: url(${(picture) => `https://construcaosocialista.com.br/api/storage/app/public/${picture.picture}`});
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-color: #CD000E;
    z-index: 1;

    @media (max-width: 1200px) {
        width: 100%;
        max-height: 250px;
        min-height: 250px;
        padding-left: -40px;
        background-size: cover;
    }
`;

export const Imagem = styled.div`
    max-height: 400px;
    min-height: 400px;
    background-image: url(${(picture) => `https://construcaosocialista.com.br/api/storage/app/public/${picture.picture}`});
    background-repeat: no-repeat;
    background-color: ${({ color }) => { if (color !== null) { return color; } else { return '#004070'; }}};
    background-position: center;
    Background-size: contain;
    position: relative;

    @media (max-width: 1200px) {
        max-height: 470px;
        min-height: 470px;
    }
`;

export const Imagem2 = styled.div`
    max-height: 400px;
    min-height: 400px;
    background-image: url(${card2});
    background-repeat: no-repeat;
    background-color: #004070;
    background-position: center;
    Background-size: contain;
    position: relative;

    @media (max-width: 1200px) {
        max-height: 470px;
        min-height: 470px;
    }
`;

export const TextPq = styled.p`
    color: ${({ cor }) => { if (cor === "primary") { return '#004070'; } else { return '#FFFFFF'; }}};
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3; 
    max-height: 80px;     
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
`;

export const Textos = styled.div`
    display: grid;
    height: 260px;

    @media (max-width: 600px) {
        height: 300px;
    }
`;

export const TextosDuplo = styled.div`
    display: grid;
    height: 260px;

    @media (max-width: 1200px) {
        height: 190px;
    }
`;

export const Right = styled.div`
    padding: 50px 20px;
    background-color: #004070;
    display: grid;
    justify-content: space-between;
    max-height: 400px;
    min-height: 400px;

    @media (max-width: 768px) {
        margin-top: -30px;
    }
`;

export const Tag = styled.p`
    font-size: 15px;
    color: #7BC7FF;
    font-weight: bold;
`;

export const TextCompartilhar = styled.p`
    font-size 15px;
    color: #7BC7FF;
`;

export const DivMultimidia = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 10;
    -webkit-border-radius: 15px;
    border-radius: 15px 0 0 15px;
    max-height: 400px;
    min-height: 400px;
    min-width: 50%;

    @media (max-width: 1200px) {
        width: 100%;
        border-radius: 15px 15px 0 0;
        max-height: 250px;
        min-height: 250px;
    }
`;

export const DivModal = styled.div``;

export const DivMensagem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
`;

export const DivPagination = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
`;

export const Paginacao = styled.button`
    width: 35px;  
    height: 35px;
    background-color: #FFFFFF;
    border: 1px solid #D6D5D5;
    border-radius: 100%;
    text-align: center;
    color: #9E9B9B;
    margin-left: 21px;
`;

export const PaginacaoAtivo = styled.button`
    width: 35px;  
    height: 35px;
    background-color: #CD000E;
    border: 1px solid #CD000E;
    border-radius: 100%;
    text-align: center;
    color: #FFFFFF;
    margin-left: 21px;
`;

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 30px;
    margin-left: 40px;
`;

export const TitleBody = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #CD000E;
    margin-left: 10px;
    margin-top: 3px;
    height: 50px;
`;

import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import MultimidiaPq from '../../components/Multimidia';
import Loading from '../../components/Loading';
import Axios from '../../utils/Axios';
import MaiorPag from '../../assets/svg/Maior';
import MenorPag from '../../assets/svg/Menor';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';

const Galeria = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  //paginacao
  const [itemsPorPage] = useState(9);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const pages = Math.ceil(dados.length / itemsPorPage);
  const startIndex = paginaAtual * itemsPorPage;
  const endIndex = startIndex + itemsPorPage;
  const currentItens = dados.slice(startIndex, endIndex);

  useEffect( () => {
    setLoading(true);
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/multimidias`).then((response) => {
      setDados(response.data);
      setLoading(false);
    })
  }, []);

  return (
    <>
      <Header min="true" name="Notícias" />
      <Container style={{ marginTop: "-200px", maxWidth: "94%", backgroundColor: "#FFFFFF", zIndex: "10000", position: "relative" }}>
        {loading ? <Loading /> :
          <>
            <Row>
              <Col xl={6}>
                  <S.DivTitulos>
                    <DestaqueIcon />
                    <S.TitleBody>GALERIA DE FOTOS</S.TitleBody>
                  </S.DivTitulos>
              </Col>
            </Row>
            <Row>
              {currentItens !== undefined && currentItens.map((item, index) => (
                <Col md={6} xl={4} sm={12} style={{ marginTop: "20px" }} key={index}>
                  {parseInt(item.modelo) === 1 ?
                      <S.DivMultimidia>
                        <MultimidiaPq height={"302px"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={item.urlYoutube} />
                      </S.DivMultimidia>
                    : 
                    item.imagem0 && item.imagem1 && item.imagem2 && item.imagem3 !== null ?
                      <S.QuatroFotos to={"/galeria/detalhes/"+item.id}>
                        <S.Cima>
                          <S.Um>
                            <img src={process.env.REACT_APP_API_URL + item.imagem0} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                          </S.Um>
                          <S.Dois>
                            <img src={process.env.REACT_APP_API_URL + item.imagem1} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                          </S.Dois>
                        </S.Cima>
                        <S.Cima>
                          <S.Um>
                            <img src={process.env.REACT_APP_API_URL + item.imagem2} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                          </S.Um>
                          <S.Dois>
                            <img src={process.env.REACT_APP_API_URL + item.imagem3} alt="" style={{ maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%", }} />
                          </S.Dois>
                        </S.Cima>
                      </S.QuatroFotos>
                    :
                      <S.DivMultimidia2 to={"/galeria/detalhes/"+item.id}>
                        {item.imagem0 ?
                          <img 
                            src={process.env.REACT_APP_API_URL + item.imagem0} 
                            alt="" 
                            style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                          />
                        : item.imagem1 ?
                          <img 
                            src={process.env.REACT_APP_API_URL + item.imagem1} 
                            alt="" 
                            style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                          />
                        : item.imagem2 ?
                          <img 
                            src={process.env.REACT_APP_API_URL + item.imagem2} 
                            alt="" 
                            style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                          />
                        : item.imagem3 ?
                          <img 
                            src={process.env.REACT_APP_API_URL + item.imagem3} 
                            alt="" 
                            style={{ height: "302px", minWidth:"100%", maxWidth:"100%" }} 
                          />
                        : ""
                        } 
                      </S.DivMultimidia2>
                    }
                  <S.Grid>
                    <S.SubTitle>{item.titulo}</S.SubTitle>
                  </S.Grid>
                </Col>
              ))}
            </Row>
            <Row>
              {currentItens.length > 0 ?
                <S.DivPagination>
                  <S.Paginacao disabled={paginaAtual === 0 ? true : false} onClick={(e) => setPaginaAtual(Number(paginaAtual-1))}><MenorPag style={{ width: "25px", height: "25px", marginLeft: "-3px" }} /></S.Paginacao>
                  {Array.from(Array(pages), (item, index) => {
                    if(paginaAtual === index){
                      return <S.PaginacaoAtivo value={index} onClick={(e) => setPaginaAtual(Number(e.target.value))}>{index + 1}</S.PaginacaoAtivo>
                    } else {
                      return <S.Paginacao value={index} onClick={(e) => setPaginaAtual(Number(e.target.value))}>{index + 1}</S.Paginacao>
                    }
                  })}
                  <S.Paginacao disabled={paginaAtual === pages-1 ? true : false } onClick={() => setPaginaAtual(Number(paginaAtual+1))}><MaiorPag style={{ width: "25px", height: "25px", marginLeft: "0px" }} /></S.Paginacao>
                </S.DivPagination>
                :
                <S.DivMensagem>
                  <S.Title>Não foi encontrado nenhum Galeria!</S.Title>
                </S.DivMensagem>
              }
            </Row>
          </>
        }
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default Galeria;
import React, { useState } from 'react';
import * as S from './styles';
import Logo from '../../assets/img/logo.png';
import FaceHeader from '../../assets/svg/FaceHeader';
import InstaHader from '../../assets/svg/InstaHeader';
import WhatsHader from '../../assets/svg/WhatsHeader';
import Lupa from '../../assets/svg/Lupa';
import Icon from '../../assets/svg/Hamburguer';
import X from '../../assets/svg/X';
import Background from '../../assets/img/banner.png';

const Header = (props) => {
    const [search, setSearch] = useState([]);
    const [open, setOpen] = useState(false);

    return (
        <>
            <S.Container>
                <S.Left>
                    <S.Hamburguer onClick={() => setOpen(!open)}>
                       <Icon />
                    </S.Hamburguer>
                </S.Left>
                <S.DivCenter to="/">
                    <img src={Logo} alt="" />
                </S.DivCenter>
                <S.Right>
                    <S.RedeSociais>
                        <S.Text>Siga a CS nas redes sociais.</S.Text>
                        <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/cs_socialista/`}>
                            <InstaHader />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/construcaosocialista`}>
                            <FaceHeader />
                        </a>
                        {/* <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                            <WhatsHader />
                        </a> */}
                    </S.RedeSociais>
                </S.Right>
            </S.Container>
            <S.DivBusca>
                {/* <S.DivSearch2>
                    <Lupa style={{ marginTop: "5px", marginLeft: "10px" }} />
                    <S.CustomInputText
                        id="search"
                        type="text"
                        name="search"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <S.DivLink to={"/noticias?busca="+search}>
                        <S.ButtonSearch>Buscar</S.ButtonSearch>
                    </S.DivLink>
                </S.DivSearch2> */}
                {/* <S.DivRedesSociais>
                    <S.Text style={{ marginTop: "10px" }}>Siga o Sindicaixa nas redes sociais.</S.Text>
                    <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                        <InstaHader />
                    </a>
                    <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                        <FaceHeader />
                    </a>
                    <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                        <WhatsHader />
                    </a>
                </S.DivRedesSociais> */}
            </S.DivBusca>
            <S.MenuMobile open={open}>
                <S.HeaderMenu>
                    <S.X>
                        <X onClick={() => setOpen(!open)} />
                    </S.X>
                    <S.Logo>
                        <img src={Logo} alt="" width={"142px"} height={"66px"} /> 
                    </S.Logo>
                    <S.Divider />
                </S.HeaderMenu>
                <S.BodyMenu>
                    <S.NavLink to="/" >HOME</S.NavLink>
                    <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                    <S.NavLink to="/documentos" >DOCUMENTOS</S.NavLink>
                    <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                    <S.NavLink to="/cultura" >CULTURA</S.NavLink>
                    <S.NavLink to="/galeria" >GALERIA DE FOTOS</S.NavLink>
                    {/* <S.NavLink to="/plataforma" >PLATAFORMA</S.NavLink> */}
                    <S.NavLink to="/links" >LINKS</S.NavLink>
                    <S.NavLink to="/contato" >CONTATO</S.NavLink>
                    <S.Divider />
                </S.BodyMenu>
                <S.FooterMenu>
                    {/* <S.Text>Siga o Sindicaixa nas redes sociais.</S.Text>
                    <S.RedeSociaisMobile>
                        <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/sindicaixa/`}>
                            <InstaHader />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sindicaixa.sindicato/`}>
                            <FaceHeader />
                        </a>
                        <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551998784504`}>
                            <WhatsHader />
                        </a>
                    </S.RedeSociaisMobile> */}
                    {/* <S.DivSearchMenu>
                        <Lupa style={{ marginTop: "5px", marginLeft: "10px" }} />
                        <S.CustomInputText
                            id="search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            placeholder="Busca:"
                        />
                    </S.DivSearchMenu> */}
                    {/* <S.Contato>
                        <S.DivTelefone>
                            <S.CustomTelefone />
                            <S.TextContatoTelefone>
                                51 32248049
                            </S.TextContatoTelefone>
                        </S.DivTelefone>
                        <S.DivEmail>
                            <S.CustomEmail />
                            <S.TextContatoEmail>
                                sindicaixa@sindicaixars.org.br
                            </S.TextContatoEmail>
                        </S.DivEmail>
                    </S.Contato> */}
                </S.FooterMenu>
            </S.MenuMobile>
            <S.ContainerMenu>
                <S.NavLink to="/" >HOME</S.NavLink>
                <S.NavLink to="/sobre" >SOBRE</S.NavLink>
                <S.NavLink to="/documentos" >DOCUMENTOS</S.NavLink>
                <S.NavLink to="/noticias" >NOTÍCIAS</S.NavLink>
                <S.NavLink to="/cultura" >CULTURA</S.NavLink>
                <S.NavLink to="/galeria" >GALERIA DE FOTOS</S.NavLink>
                {/* <S.NavLink to="/plataforma" >PLATAFORMA</S.NavLink> */}
                <S.NavLink to="/links" >LINKS</S.NavLink>
                <S.NavLink to="/contato" >CONTATO</S.NavLink>
            </S.ContainerMenu>
            <S.DivImagem min={props.min}>
                <img src={Background} alt="" width={"100%"} height={"650px"} />
            </S.DivImagem>
        </>
    )
}

export default Header;
import styled from 'styled-components';

export const CustomModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100vw;
    height: 100vh;
    background-color: #780C0F;
    opacity: 0.94;
    display: flex;
    justify-content: center;
    padding-top: 10%;
`;

export const DivModal = styled.div`
    width: 350px;
    height: 300px;
`;

export const HeaderModal = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Left = styled.div``;

export const Right = styled.div`
    cursor: pointer;
`;

export const ModalBody = styled.div`
    width: 350px;
    height: 300px;
    background-color: #780C0F;
    border-radius: 20px;
    margin-top: 10px;
    opacity: 1;
    text-align: center;
`;

export const TextWhite = styled.p`
    font-size: 25px;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 10px;
`;

export const Redes = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

export const Face = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 15px;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const Insta = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 15px;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
`;
import React from 'react';

function FaceLinks(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.062" height="39.061" viewBox="0 0 39.062 39.061" fill="none" {...props}>
            <g id="Grupo_9" data-name="Grupo 9" transform="translate(0.5 0.5)">
                <path id="Caminho_56" data-name="Caminho 56" d="M19.531,39.061a19.535,19.535,0,0,1-7.6-37.526,19.535,19.535,0,0,1,15.2,35.991A19.409,19.409,0,0,1,19.531,39.061ZM19.531,1A18.53,18.53,0,1,0,38.062,19.531,18.551,18.551,0,0,0,19.531,1Z" transform="translate(-0.5 -0.5)" fill="#CD000E"/>
                <path id="Caminho_57" data-name="Caminho 57" d="M16.691,31.223h4.535V19.432h3.165l.337-3.948h-3.5V13.235c0-.931.181-1.3,1.048-1.3h2.454v-4.1h-3.14c-3.375,0-4.9,1.544-4.9,4.5v3.148H14.332v4h2.359Z" transform="translate(-0.5 -0.5)" fill="#CD000E"/>
            </g>
        </svg>
    )
}

export default FaceLinks;
import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    justify-content: center;
    text-align: center;
    max-width: 80%;
    margin-left: 10%;
    height: 120px;
    margin-bottom: 30px;
    padding: 20px;
`;

export const Juntar = styled.div`
`;

export const Body = styled.div`
    max-height: 200px;
    min-height: 200px;
    background-image: url(${(picture) => `http://sindicaixars.web227.uni5.net/api/storage/app/public/${picture.picture}`});
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    background-position: center;
    Background-size: contain;
    position: relative;
    border-radius: 15px;
`;
    
export const Title = styled.p`
    color: #CD000E;
    font-size: ${({ tamanho }) => { if (tamanho > '80') { return '15px'; } else { return '20px'; }}};
    font-weight: bold;
    margin-bottom: 30px;
`;

export const Text = styled.p`
    color: #707070;
    font-size: 15px;
`;

export const RedeSocial = styled.div`
    display: flex;
    max-width: 90%;
    min-width: 300px;
    margin-left: 5%;
    justify-content: space-around;
`;
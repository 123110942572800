import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const DivPar = styled.section`
    display: flex;
    align-items: center;
    height: 300px;
    justify-content: center;
    margin: 30px;
    min-width: 300px;
`;

export const Container = styled.div``;

export const DivMobile = styled.div`
    display: grid;
    gap: 40px;

    @media(min-width: 768px){
        display: none;
    }
`;

export const DivDesktop = styled.div`
    @media(max-width: 768px){
        display: none;
    }
`;

export const CustomCarousel = styled(Carousel)`
    .react-multi-carousel-dot--active button {
        background-color: #CD000E;
    }

    .react-multi-carousel-dot button {
        border-color: #CD000E;
    }
`;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import Axios from '../../utils/Axios';
import * as S from './styles';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';
import HTMLReactParser from 'html-react-parser';
import Loading from '../../components/Loading';

const Sobre = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect( () => {
    setLoading(true);
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/sobre`).then((response) => {
      setDados(response.data);
      setLoading(false);
    })
  }, []);

  return (
    <>
      <Header min="true" name="Sobre"/>
      <Container style={{ marginTop: "-200px", maxWidth: "94%", backgroundColor: "#FFFFFF", zIndex: "10000", position: "relative" }}>
        {loading ? <Loading /> :
          <>
            <Row>
              <Col xl={6}>
                <S.DivTitulos>
                  <DestaqueIcon />
                  <S.TitleBody>SOBRE</S.TitleBody>
                </S.DivTitulos>
              </Col>
              {/* <Col xl={6} style={{ display: "flex", justifyContent: "end", marginTop: "-48px" }}>
                <S.DivGrid>
                  <S.DivBusca>
                    <S.AreaBusca>
                      <S.DivSearch2>
                        <Lupa style={{ marginTop: "5px"}} />
                        <S.CustomInputText
                            id="search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                        <S.DivLink to={"/noticias?busca="+search}>
                            <S.ButtonSearch>Buscar</S.ButtonSearch>
                        </S.DivLink>
                      </S.DivSearch2>
                    </S.AreaBusca>
                  </S.DivBusca>
                </S.DivGrid>
              </Col> */}
            </Row>
            <Row style={{ marginTop: "20px", maxWidth: "94%", marginLeft: "3%" }}>
              <Col xl={12}>
                <S.Title>{dados.titulo}</S.Title>
                <S.Text>{HTMLReactParser(String(dados?.texto?.replace('class="ql-align-center"', 'class="ql-align-center" style="display: flex; justify-content: center"')))}</S.Text>
              </Col>
            </Row>
          </>
        }
      </Container>
      <Footer />
      <Assinatura />
    </>
  );
};

export default Sobre;
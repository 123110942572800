import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import CardLinks from '../../components/CardLinks';
import Axios from '../../utils/Axios';
import Loading from '../../components/Loading';
import * as S from './styles';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';

const Links = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect( () => {
    setLoading(true);
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/links`).then((response) => {
      setDados(response.data)
      setLoading(false);
    })
  }, []);
  console.log(dados, "mmax dados")
  return (
    <>
      <Header min="true" name="Links"/>
      <Container style={{ marginTop: "-200px", maxWidth: "94%", backgroundColor: "#FFFFFF", zIndex: "10000", position: "relative" }}>
        {loading ? <Loading /> :
          <>
            <Row>
              <Col xl={6}>
                <S.DivTitulos>
                  <DestaqueIcon />
                  <S.TitleBody>LINKS</S.TitleBody>
                </S.DivTitulos>
              </Col>
            </Row>
          </>
        }
        <Row style={{ marginTop: "100px" }}>
          {dados !== undefined && dados.map((item, index) => (
            <Col xl={3} lg={4} sm={6} key={index}>
              <CardLinks texto={item.texto} titulo={item.titulo} face={item.face} insta={item.insta} twitter={item.twitter} site={item.site} whats={item.whats} imagem={item.imagem} />
            </Col>
          ))}
        </Row>
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default Links;
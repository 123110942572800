import React, { useState, useEffect } from "react";
import * as S from './styled';
import Axios from '../../utils/Axios';

const CarouselParceiros = () => {
  const [dataParceiros, setDataParceiros] = useState([]);
  // const [largura, setLargura] = useState("");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1
    }
  };

  useEffect( () => {
    // if(typeof window !== "undefined"){
    //   setLargura(window?.screen.width)
    // }

    Axios.get("/campanha").then((response) => {
      setDataParceiros(response.data)
    })
  }, []);

  

  return (
    <S.CustomCarousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      infinite={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {dataParceiros.map((item, index) => (
        <S.DivPar key={index}>
          <img src={`https://construcaosocialista.com.br/api/storage/app/public/${item.picture}`} alt="" width="300px" height="300px" style={{ objectFit: "contain" }} />
        </S.DivPar>
      ))}
    </S.CustomCarousel>
  );
}

export default CarouselParceiros;
import styled from 'styled-components';
import { Link } from "react-router-dom";
import Email from '../../assets/svg/Email';
import Telefone from '../../assets/svg/Telefone';

export const ContainerMenu = styled.section`
    display: flex;
    background-color: #780C0F;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: relative;
    gap: 45px;
    margin-top: 40px;

    @media (max-width: 1367px) {
        display: none;
    }
`;

export const DivCenter = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
`;

export const Fundo = styled.div`
    background-color: #780C0F;
    height: 200px;
    margin-top: 60px;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const FundoMobile = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: grid;
        background-color: #CD000E;
        height: 600px;
        justify-content: center;
    }
`;

export const Left = styled.div`
    display: grid;
    justify-content: start;
    margin-top: 100px;
    margin-left: 200px;
`;

export const TextRedes = styled.p`
    font-size: 15;
    color: #FFFFFF;
    text-align: center;
    margin-top: 110px;
    margin-left: -30px;
`;

export const DivRedes = styled.div`
    display: flex;
    justify-content: center;
    gap: 35px;
    margin-top: 17px;
    margin-left: -30px;
`;

export const Right = styled.div`
    display: grid;
    margin-top: 100px;

    @media (max-width: 1200px) {
        display: none;
    }
`;

export const RightTablet = styled.div`
    display: none;

    @media (max-width: 1200px) {
        display: grid;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-content: center;
    }

    @media (max-width: 900px) {
        padding-left: 60px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

// export const NavLink = styled(Link)`
//     color: #FFFFFF;
//     text-decoration: none;
//     font-size: 20px;
//     margin-right: 20px;

//     &:hover {
//         color: #FFFFFF99;
//         text-decoration: underline;
//     }

//     @media (max-width: 1400px) {
//         font-size: 15px;
//         margin-right: 10px;
//     }
// `;

export const NavLink = styled(Link)`
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
        color: #EDE253;
        text-decoration: underline;
    }
`;

export const TextContato = styled.p`
    color: #FFFFFF;
    font-size: 22px;
    font-width: 600;

    @media (max-width: 1200px) {
        font-size: 25px;
        margin-left: 20px;
        max-width: 100%;
    }

    @media (max-width: 1100px) {
        font-size: 20px;
        margin-left: 10px;
        max-width: 100%;
    }

    @media (max-width: 900px) {
        font-size: 18px;
        margin-left: 5px;
        max-width: 100%;
    }
`;

export const CustomEmail = styled(Email)`
    & path {
        fill: #FFFFFF;
    }
`;

export const CustomTelefone = styled(Telefone)`
    & path {
        fill: #FFFFFF;
    }
`;

export const Informacoes = styled.div`
    display: grid;
    margin-top: 80px;
`;

export const Title = styled.p`
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;

    @media (max-width: 1400px) {
        font-size: 20px;
    }
`;

export const Text = styled.p`
    font-size: 13px;
    color: #FFFFFF;
`;

export const TextLink = styled(Link)`
    font-size: 13px;
    color: #FFFFFF;
    text-decoration: none;
    margin-right: 20px;

    &:hover {
        color: #FFFFFF99;
        text-decoration: underline;
    }
`;

export const DivTelefone = styled.div`
    display: flex;
    height: 50px;
`;

export const DivEmail = styled.div`
    display: flex;
    height: 50px;
    margin-top: 30px;
`;

export const HeaderMenu = styled.div`
    display: grid;
    width: 378px;
    height: 150px;
    margin-bottom: -100px;
    justify-content: center;
    margin-top: 30px;
    border-bottom: 1px groove #7BC7FF;
    border-left: 0;
    border-right: 0;
    border-top: 0;
`;

export const Logo = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
`;

export const BodyMenu = styled.div`
    display: grid;
    height: 300px;
    width: 378px;
    text-align: center;
    margin-top: 60px;
`;

export const Divider = styled.hr`
    border-top: 1px groove #7BC7FF;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    width: 100%;
`;

export const RedeSociaisMobile = styled.div`
    display: flex;
    gap: 35px;
    justify-content: center;

    @media (min-width: 1367px) {
        display: none;
    }
`;

export const Contato = styled.div`
    display: grid;
    justify-content: center;
    margin-top: 40px;
    text-align: center;
`;

export const TextContatoTelefone = styled.p`
    color: #FFFFFF;
    font-size: 25px;
    font-width: 600;
    margin-left: 15px;
`;

export const TextContatoEmail = styled.p`
    color: #FFFFFF;
    font-size: 15px;
    font-width: 600;
    margin-left: 15px;
`;
import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Assinatura from '../../../components/Assinatura';
// import imagemPq from '../../../assets/img/imagemPq.png';
// import Audio from '../../../components/Audio';
import Multimidia from '../../../components/Multimidia';
// import ImagemGaleria from '../../../components/ImagemGaleria';
// import XGr from '../../../assets/svg/XGr';
import Axios from '../../../utils/Axios';
import HTMLReactParser from 'html-react-parser';
// import Face from '../../../assets/svg/Face';
// import Twitter from '../../../assets/svg/Twitter';
// import Whats from '../../../assets/svg/Whats';
// import FaceCinza from '../../../assets/svg/FaceCinza';
// import TwitterCinza from '../../../assets/svg/TwitterCinza';
// import WhatsCinza from '../../../assets/svg/WhatsCinza';
// import FaceAzul from '../../../assets/svg/FaceAzul';
// import TwitterAzul from '../../../assets/svg/TwitterAzul';
// import WhatsAzul from '../../../assets/svg/WhatsAzul';
import Audio from '../../../components/Audio';
import Loading from '../../../components/Loading';

const NoticiasDetalhes = () => {
  // const [modal, setModal] = useState(false);
  const [fonte, setFonte] = useState(2);
  const [dados, setDados] = useState([]);
  const [dadosOff, setDadosOff] = useState([]);
  const [loading, setLoading] = useState(true);

  // const urlParams = new URLSearchParams(window.location.search);
  // const idGet = urlParams.get('id');
  const {id } = useParams();

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/noticias/${id}`).then((response) => {
      setDados(response.data);
      setLoading(false);
    })
  }, [id]);

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/noticias/off/${id}`).then((response) => {
      setDadosOff(response.data)
    })
  }, [id]);

  return (
    <>
      <Header min="true" name="Notícias"/>
      <Container style={{ maxWidth: "80%"}}>
        <S.DivButton>
          <S.DivCompartilhar>
            {/* <S.Texto>Compartilhar</S.Texto>
            <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${dados.id}`}>
              <S.CustomFace />
            </a>
            <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${dados.id}`}>
              <S.CustomTwitter />
            </a>
            <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${dados.id}`} data-action="share/whatsapp/share">
              <S.CustomWhats />
            </a> */}
          </S.DivCompartilhar>
          <S.DivFonte>
            <S.CustomButton onClick={() => setFonte(fonte + 1) } disabled={fonte === 4 ? true : false} >
              <S.CustomAMais />
            </S.CustomButton>
            <S.CustomButton onClick={() => setFonte(fonte - 1) } disabled={fonte === 0 ? true : false}>
              <S.CustomAMenos />
            </S.CustomButton>
          </S.DivFonte>
        </S.DivButton>
          {/* <Row>
            <Col md={12}>
              <S.Title fonte={fonte}>PLC 48/2022</S.Title>
            </Col>
            <Col md={12}>
              <S.SubTitle fonte={fonte}>Mais um duro golpe na sociedade gaúcha!</S.SubTitle>
            </Col>
            <Col md={12}>
              <S.Text fonte={fonte}>O Sindicaixa – organização sindical que congrega os trabalhadores da extinta Caixa Econômica Estadual do Rio Grande do Sul – tem como princípio o compromisso de atuar na defesa dos interesses dos seus associados e da classe trabalhadora em geral.
                Tem como filosofia garantir os direitos dos seus associados através da luta direta, no combate aos governos que sucateiam os serviços públicos e impõem políticas que retiram direitos destes trabalhadores. Além disto, procura atuar também na luta jurídica e parlamentar, garantindo aos servidores a ele ligados, uma atuação firme por salários dignos e melhores condições de trabalho. Também a defesa dos aposentados está entre as suas prioridades.
                Por pertencer a uma categoria plural em seus princípios ideológicos, o Sindicaixa prima pela democracia em todas as suas instâncias organizativas, e defende a unidade do conjunto dos servidores públicos para lutar por data-base e ampliação dos direitos destes trabalhadores, combatendo os privilégios no setor público, sem espaço para o autoritarismo e o sectarismo em suas ações e deliberações.
                Sejam todos bem-vindos! Sejam todas bem-vindas!</S.Text>
            </Col>
          </Row> */}
        {/* <Row>
          <Col md={12}>
            <S.Title fonte={fonte}>PLC 48/2022</S.Title>
          </Col>
          <Col md={12}>
            <S.SubTitle fonte={fonte}>Mais um duro golpe na sociedade gaúcha!</S.SubTitle>
          </Col>
          <Col md={4}>
            <img src={imagemPq} alt="" style={{ maxWidth: "100%", marginTop: "20px" }} />
          </Col>
          <Col md={8}>
            <S.Text fonte={fonte}>O Sindicaixa – organização sindical que congrega os trabalhadores da extinta Caixa Econômica Estadual do Rio Grande do Sul – tem como princípio o compromisso de atuar na defesa dos interesses dos seus associados e da classe trabalhadora em geral.
              Tem como filosofia garantir os direitos dos seus associados através da luta direta, no combate aos governos que sucateiam os serviços públicos e impõem políticas que retiram direitos destes trabalhadores. Além disto, procura atuar também na luta jurídica e parlamentar, garantindo aos servidores a ele ligados, uma atuação firme por salários dignos e melhores condições de trabalho. Também a defesa dos aposentados está entre as suas prioridades.
              Por pertencer a uma categoria plural em seus princípios ideológicos, o Sindicaixa prima pela democracia em todas as suas instâncias organizativas, e defende a unidade do conjunto dos servidores públicos para lutar por data-base e ampliação dos direitos destes trabalhadores, combatendo os privilégios no setor público, sem espaço para o autoritarismo e o sectarismo em suas ações e deliberações.
              Sejam todos bem-vindos! Sejam todas bem-vindas!
            </S.Text>
          </Col>
        </Row> */}
        {loading ? <Loading /> :
          <Row>
            <Col md={12}>
              <S.Title fonte={fonte}>{dados.cartola}</S.Title>
            </Col>
            {dados.posicaoVideo === "1" ?
              <S.CenterYoutube>
                <Multimidia height={"100%"} width={"100%"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={dados.urlYoutube} />
              </S.CenterYoutube> : ""
            }
            <Col md={12}>
              <S.SubTitle fonte={fonte}>{dados.titulo}</S.SubTitle>
            </Col>
            <Col md={12}>
                {dados.modelo === "3" ?
                  dados.posicaoVideo === "2" ?
                    <S.CenterYoutube>
                      <Multimidia height={"100%"} width={"100%"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={dados.urlYoutube} />
                    </S.CenterYoutube> 
                  : ""
                :
                  <S.Center>
                    <img src={`http://sindicaixars.web227.uni5.net/api/storage/app/public/${dados.picture}`} alt="" style={{ marginTop: "20px", maxWidth: "100%" }} />
                  </S.Center>
                }
            </Col>
            <Col md={12}>
              <S.Text fonte={fonte}>{HTMLReactParser(String(dados.texto.replace('class="ql-align-center"', 'class="ql-align-center" style="display: flex; justify-content: center"')))}</S.Text>
            </Col>
            {dados.posicaoVideo === "3" ?
              <S.CenterYoutube>
                <Multimidia height={"100%"} width={"100%"} minWidth={"100%"} maxWidth={"100%"} urlYoutube={dados.urlYoutube} />
              </S.CenterYoutube> : ""
            }
          </Row>
        }
      
        <Row style={{ marginBottom: "200px" }}>
          <Col md={12}>
            <S.DivTitulos>
              <S.TitleBody>Outras notícias</S.TitleBody>
            </S.DivTitulos>
          </Col>
          {dadosOff !== undefined && dadosOff.map((item, index) => (
            item.modelo === "2" ?
              <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                  <S.DivCard>
                      <S.DivLink to={"/noticias/detalhes/"+item.id}>
                          <S.Imagem picture={item.picture} color={item.color} />
                      </S.DivLink>
                  </S.DivCard>
              </Col>
            : item.modelo === "3" || item.modelo === "4" ?
              <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                <S.DivCard>
                    <S.Duplo>
                        {/* <S.DivLink to={"/noticias/detalhes/"+item.id}>
                            <S.ImagemDuplo picture={item.picture}/>
                        </S.DivLink> */}
                        <S.FundoDuplo cor="white">
                            <S.DivLink to={"/noticias/detalhes/"+item.id}>
                                <S.TextosDuplo>
                                    {/* <S.Lapela>{item.cartola}</S.Lapela> */}
                                    <S.TitleDuplo cor="white">{item.titulo}</S.TitleDuplo>
                                    <S.TextDuplo cor="white">{item.chamada}</S.TextDuplo>
                                </S.TextosDuplo>
                            </S.DivLink>
                            <S.BotoesDuplo>
                                {/* <S.Divider />
                                <S.RedeSociais>
                                    <S.Text2 cor="white">Compartilhar</S.Text2>
                                    <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                        <FaceCinza width="30px" height="30px" />
                                    </a>
                                    <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                        <TwitterCinza width="30px" height="30px" />
                                    </a>
                                    <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                        <WhatsCinza width="30px" height="30px" />
                                    </a>
                                </S.RedeSociais> */}
                            </S.BotoesDuplo>
                        </S.FundoDuplo>
                    </S.Duplo>
                </S.DivCard>
              </Col> 
            : item.modelo === "5" ?
              <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                <S.Fundo cor="secundary" to={"/noticias/detalhes/"+item.id}>
                    {/* <S.Lapela cor="white">{item.cartola}</S.Lapela> */}
                    <Audio size="medio" cor="azul_claro" />
                    <S.TextPq cor="primary" style={{ marginTop: "-170px" }}>{item.chamada}</S.TextPq>
                    <S.BotoesAudio>
                        {/* <S.Divider cor="primary" />
                        <S.RedeSociais>
                            <S.Text2 cor="primary">Compartilhar</S.Text2>
                            <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                <FaceAzul width="30px" height="30px" />
                            </a>
                            <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                <TwitterAzul width="30px" height="30px" />
                            </a>
                            <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                <WhatsAzul width="30px" height="30px" />
                            </a>
                        </S.RedeSociais> */}
                    </S.BotoesAudio>
                </S.Fundo>
              </Col>
            : item.modelo === "6" ?
              <Col xl={3} md={6} style={{ marginTop: "20px" }} key={index}>
                  <S.DivCard>
                      <S.Fundo>
                          <S.DivLink to={"/noticias/detalhes/"+item.id}>
                              <S.Textos>
                                  {/* <S.Lapela>{item.cartola}</S.Lapela> */}
                                  <S.Title2>{item.titulo}</S.Title2>
                                  {item.titulo.length > 60 ? "" : <S.Text3>{item.chamada}</S.Text3>}
                              </S.Textos>
                          </S.DivLink>
                          <S.Botoes>
                              {/* <S.Divider />
                              <S.RedeSociais>
                                  <S.Text2>Compartilhar</S.Text2>
                                  <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                      <Face width="30px" height="30px" />
                                  </a>
                                  <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`}>
                                      <Twitter width="30px" height="30px" />
                                  </a>
                                  <a href={`whatsapp://send?text=https%3A//sindicaixars.web227.uni5.net/noticias/detalhes/${item.id}`} data-action="share/whatsapp/share">
                                      <Whats width="30px" height="30px" />
                                  </a>
                              </S.RedeSociais> */}
                          </S.Botoes>
                      </S.Fundo>
                  </S.DivCard>
              </Col>
            : ""   
          ))}
        </Row>
        {/* <Row>
          <Col md={12}>
            <S.Title fonte={fonte}>PLC 48/2022</S.Title>
          </Col>
          <Col md={12}>
            <S.SubTitle fonte={fonte}>Mais um duro golpe na sociedade gaúcha!</S.SubTitle>
          </Col>
          <Col md={12}>
            <Audio style={{ marginTop: "20px", height: "300px" }} size="grande" />
          </Col>
          <Col md={12}>
            <S.Text fonte={fonte}>O Sindicaixa – organização sindical que congrega os trabalhadores da extinta Caixa Econômica Estadual do Rio Grande do Sul – tem como princípio o compromisso de atuar na defesa dos interesses dos seus associados e da classe trabalhadora em geral.
              Tem como filosofia garantir os direitos dos seus associados através da luta direta, no combate aos governos que sucateiam os serviços públicos e impõem políticas que retiram direitos destes trabalhadores. Além disto, procura atuar também na luta jurídica e parlamentar, garantindo aos servidores a ele ligados, uma atuação firme por salários dignos e melhores condições de trabalho. Também a defesa dos aposentados está entre as suas prioridades.
              Por pertencer a uma categoria plural em seus princípios ideológicos, o Sindicaixa prima pela democracia em todas as suas instâncias organizativas, e defende a unidade do conjunto dos servidores públicos para lutar por data-base e ampliação dos direitos destes trabalhadores, combatendo os privilégios no setor público, sem espaço para o autoritarismo e o sectarismo em suas ações e deliberações.
              Sejam todos bem-vindos! Sejam todas bem-vindas!
            </S.Text>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <S.Title fonte={fonte}>PLC 48/2022</S.Title>
          </Col>
          <Col md={12}>
            <S.SubTitle fonte={fonte}>Mais um duro golpe na sociedade gaúcha!</S.SubTitle>
          </Col>
          <Col md={12}>
            <MultimidiaPq height={"900px"} minWidth={"100%"} />
          </Col>
          <Col md={12}>
            <S.Text fonte={fonte}>O Sindicaixa – organização sindical que congrega os trabalhadores da extinta Caixa Econômica Estadual do Rio Grande do Sul – tem como princípio o compromisso de atuar na defesa dos interesses dos seus associados e da classe trabalhadora em geral.
              Tem como filosofia garantir os direitos dos seus associados através da luta direta, no combate aos governos que sucateiam os serviços públicos e impõem políticas que retiram direitos destes trabalhadores. Além disto, procura atuar também na luta jurídica e parlamentar, garantindo aos servidores a ele ligados, uma atuação firme por salários dignos e melhores condições de trabalho. Também a defesa dos aposentados está entre as suas prioridades.
              Por pertencer a uma categoria plural em seus princípios ideológicos, o Sindicaixa prima pela democracia em todas as suas instâncias organizativas, e defende a unidade do conjunto dos servidores públicos para lutar por data-base e ampliação dos direitos destes trabalhadores, combatendo os privilégios no setor público, sem espaço para o autoritarismo e o sectarismo em suas ações e deliberações.
              Sejam todos bem-vindos! Sejam todas bem-vindas!
            </S.Text>
          </Col>
        </Row> */}
        {/* <Row>
          <Col md={12}>
            <S.Title fonte={fonte}>PLC 48/2022</S.Title>
          </Col>
          <Col md={12}>
            <S.SubTitle fonte={fonte}>Mais um duro golpe na sociedade gaúcha!</S.SubTitle>
          </Col>
          <Col md={12}>
            <S.Text fonte={fonte}>O Sindicaixa – organização sindical que congrega os trabalhadores da extinta Caixa Econômica Estadual do Rio Grande do Sul – tem como princípio o compromisso de atuar na defesa dos interesses dos seus associados e da classe trabalhadora em geral.
              Tem como filosofia garantir os direitos dos seus associados através da luta direta, no combate aos governos que sucateiam os serviços públicos e impõem políticas que retiram direitos destes trabalhadores. Além disto, procura atuar também na luta jurídica e parlamentar, garantindo aos servidores a ele ligados, uma atuação firme por salários dignos e melhores condições de trabalho. Também a defesa dos aposentados está entre as suas prioridades.
              Por pertencer a uma categoria plural em seus princípios ideológicos, o Sindicaixa prima pela democracia em todas as suas instâncias organizativas, e defende a unidade do conjunto dos servidores públicos para lutar por data-base e ampliação dos direitos destes trabalhadores, combatendo os privilégios no setor público, sem espaço para o autoritarismo e o sectarismo em suas ações e deliberações.
              Sejam todos bem-vindos! Sejam todas bem-vindas!
            </S.Text>
          </Col>
          <Col md={6} onClick={() => setModal(!modal) }>
            <img src={imagemPq} alt="" style={{ maxWidth: "100%", minWidth: "100%", marginTop: "20px", cursor: "pointer", borderRadius: "15px" }} />
          </Col>
          <Col md={6} onClick={() => setModal(!modal) }>
            <img src={imagemPq} alt="" style={{ maxWidth: "100%", minWidth: "100%", marginTop: "20px", cursor: "pointer", borderRadius: "15px" }} />
          </Col>
          <Col md={6} onClick={() => setModal(!modal) }>
            <img src={imagemPq} alt="" style={{ maxWidth: "100%", minWidth: "100%", marginTop: "20px", cursor: "pointer", borderRadius: "15px" }} />
          </Col>
          <Col md={6} onClick={() => setModal(!modal) }>
            <img src={imagemPq} alt="" style={{ maxWidth: "100%", minWidth: "100%", marginTop: "20px", cursor: "pointer", borderRadius: "15px" }} />
          </Col>
        </Row> */}
      </Container>  
      <Footer />
      <Assinatura />
      {/* {modal && 
        <>
          <S.Modal>
            <S.Legenda style={{ marginRight: "-80px" }}>teste</S.Legenda>
            <ImagemGaleria />
            <XGr style={{ marginTop: "-130px", marginLeft: "-70px", cursor: "pointer" }} onClick={() => setModal(!modal) } />
          </S.Modal>
        </>
      } */}
    </>
  );
};

export default NoticiasDetalhes;
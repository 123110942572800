import styled from 'styled-components';
import { Link } from "react-router-dom";

export const DivTitulos = styled.div`
    display: flex;
    height: 50px;
    margin-top: 50px;
`;

export const TextBody = styled.text`
    font-size: 18px;
    color: #707070;
`;

export const Grid = styled.div`
    display: grid;
    margin-top: 15px;

    @media (max-width: 1200px) {
        margin-top: 50px;
    }
`;

export const Title = styled.text`
    font-size: 15px;
    color: #7BC7FF;
    font-weight: bold;
`;

export const SubTitle = styled.div`
    color: #780C0F;
    font-size: 35px;
    margin-top: 10px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: 1200px) {
        margin-top: -30px;
        line-height: 1;
    }
    @media (max-width: 1000px) {
        font-size: 30px;
    }
    @media (max-width: 500px) {
        font-size: 27px;
    }
    @media (max-width: 400px) {
        font-size: 25px;
    }
`;

export const Texto = styled.text`
    font-size: 15px;
    color: #707070;
`;

export const Fundo = styled.div`
`;

export const DivMultimidia = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 10;
    -webkit-border-radius: 15px;
    border-radius: 15px;
`;

export const DivMultimidia2 = styled(Link)`
    overflow: hidden;
    position: relative;
    z-index: 10;
    -webkit-border-radius: 15px;
    border-radius: 15px;
`;

export const QuatroFotos = styled(Link)`
    width: 100%;
    height: 302px;
    display: grid;
`;

export const Cima = styled.div`
    display: flex;
    width: 100%;
    height: 151px;
`;

export const Um = styled.div`
    max-width: 50%;
    min-width: 50%;
`;

export const Dois = styled.div`
    max-width: 50%;
    min-width: 50%;
`;

export const DivPagination = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
`;

export const Paginacao = styled.button`
    width: 35px;  
    height: 35px;
    background-color: #FFFFFF;
    border: 1px solid #D6D5D5;
    border-radius: 100%;
    text-align: center;
    color: #9E9B9B;
    margin-left: 21px;
`;

export const PaginacaoAtivo = styled.button`
    width: 35px;  
    height: 35px;
    background-color: #780C0F;
    border: 1px solid #780C0F;
    border-radius: 100%;
    text-align: center;
    color: #FFFFFF;
    margin-left: 21px;
`;

export const DivMensagem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
`;

export const TitleBody = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #CD000E;
    margin-left: 10px;
    margin-top: 3px;
    height: 50px;
`;

import React from 'react';

function Documentos(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.688" height="45" viewBox="0 0 35.688 45">
  <g id="Grupo_3233" data-name="Grupo 3233" transform="translate(-1905 -1512)">
    <path id="Caminho_647" data-name="Caminho 647" d="M.442,45A1.137,1.137,0,0,1,0,43.975Q.013,22.493.007,1.011c0-.8.2-1,1.018-1Q13.5.008,25.981,0a1.365,1.365,0,0,1,1.043.435q4.084,4.023,8.2,8.019a1.449,1.449,0,0,1,.468,1.125q-.012,17.177,0,34.354A1.182,1.182,0,0,1,35.246,45ZM34.26,9.889h-.492c-1.728,0-3.456,0-5.184,0A2.767,2.767,0,0,1,25.6,6.9c0-1.669,0-3.339,0-5.008,0-.154-.012-.307-.019-.45H1.439V43.567H34.26ZM27,2.372v.474c0,1.376,0,2.752,0,4.129A1.388,1.388,0,0,0,28.527,8.5c1.274.008,2.548,0,3.822,0h.909L27,2.372" transform="translate(1905 1512)" fill="#780c0f"/>
    <path id="Caminho_648" data-name="Caminho 648" d="M24.872,96.538q6.327,0,12.654,0c.2,0,.468-.029.589.081a1.02,1.02,0,0,1,.357.671c-.006.2-.24.431-.429.578-.117.091-.337.066-.511.066q-12.654,0-25.308,0c-.673,0-.96-.2-.975-.681a.647.647,0,0,1,.617-.711c.16-.015.322-.008.483-.008H24.872" transform="translate(1897.877 1450.873)" fill="#780c0f"/>
    <path id="Caminho_649" data-name="Caminho 649" d="M24.869,107.318q6.327,0,12.654,0c.2,0,.468-.031.59.079a1.023,1.023,0,0,1,.359.671c-.006.2-.239.432-.427.579a.916.916,0,0,1-.51.067q-12.654,0-25.308,0c-.687,0-.987-.224-.978-.723a.642.642,0,0,1,.615-.669c.16-.015.322-.008.483-.008H24.869" transform="translate(1897.877 1444.047)" fill="#780c0f"/>
    <path id="Caminho_650" data-name="Caminho 650" d="M24.872,85.538q6.327,0,12.654,0c.2,0,.468-.029.589.081a1.02,1.02,0,0,1,.357.671c-.006.2-.24.431-.429.578-.117.091-.337.066-.511.066q-12.654,0-25.308,0c-.673,0-.96-.2-.975-.681a.647.647,0,0,1,.617-.711c.16-.015.322-.008.483-.008H24.872" transform="translate(1897.877 1457.838)" fill="#780c0f"/>
    <path id="Caminho_651" data-name="Caminho 651" d="M24.872,74.538q6.327,0,12.654,0c.2,0,.468-.029.589.081a1.02,1.02,0,0,1,.357.671c-.006.2-.24.431-.429.578-.117.091-.337.066-.511.066q-12.654,0-25.308,0c-.673,0-.96-.2-.975-.681a.647.647,0,0,1,.617-.711c.16-.015.322-.008.483-.008H24.872" transform="translate(1897.877 1464.803)" fill="#780c0f"/>
    <path id="Caminho_652" data-name="Caminho 652" d="M24.872,63.538q6.327,0,12.654,0c.2,0,.468-.029.589.081a1.02,1.02,0,0,1,.357.671c-.006.2-.24.431-.429.578-.117.091-.337.066-.511.066q-12.654,0-25.308,0c-.673,0-.96-.2-.975-.681a.647.647,0,0,1,.617-.711c.16-.015.322-.008.483-.008H24.872" transform="translate(1897.877 1471.769)" fill="#780c0f"/>
    <path id="Caminho_653" data-name="Caminho 653" d="M24.872,53.378q6.327,0,12.654,0c.2,0,.468-.029.589.081a1.02,1.02,0,0,1,.357.671c-.006.2-.24.431-.429.578-.117.091-.337.066-.511.066q-12.654,0-25.308,0c-.673,0-.96-.2-.975-.681a.647.647,0,0,1,.617-.711c.16-.015.322-.008.483-.008H24.872" transform="translate(1897.877 1478.202)" fill="#780c0f"/>
    <path id="Caminho_654" data-name="Caminho 654" d="M24.877,43.083H37.442a3.216,3.216,0,0,1,.526.019.607.607,0,0,1,.532.607.621.621,0,0,1-.452.713,1.842,1.842,0,0,1-.522.048q-12.652,0-25.3,0c-.68,0-.96-.2-.971-.678a.637.637,0,0,1,.624-.7c.16-.014.322-.008.483-.008H24.877" transform="translate(1897.876 1484.72)" fill="#780c0f"/>
    <path id="Caminho_655" data-name="Caminho 655" d="M24.9,32.358H37.506a2.181,2.181,0,0,1,.481.021A.617.617,0,0,1,38.5,33a.609.609,0,0,1-.488.684,2.262,2.262,0,0,1-.523.046q-12.61,0-25.22,0a2.422,2.422,0,0,1-.523-.044.6.6,0,0,1-.495-.677.622.622,0,0,1,.548-.64,2.265,2.265,0,0,1,.438-.014H24.9" transform="translate(1897.873 1491.513)" fill="#780c0f"/>
    <path id="Caminho_656" data-name="Caminho 656" d="M17.186,12.965q-2.568,0-5.135,0c-.5,0-.766-.213-.8-.619a.659.659,0,0,1,.567-.759,1.883,1.883,0,0,1,.35-.021q5.026,0,10.051,0c.568,0,.837.175.9.566a.68.68,0,0,1-.756.831c-1.726.006-3.453,0-5.179,0" transform="translate(1897.879 1504.677)" fill="#780c0f"/>
    <path id="Caminho_657" data-name="Caminho 657" d="M17.156,22.606q-2.545,0-5.09,0a.735.735,0,0,1-.771-.382.684.684,0,0,1,.52-.993,2.8,2.8,0,0,1,.437-.022q4.914,0,9.829,0a2.8,2.8,0,0,1,.481.028.618.618,0,0,1,.547.721.6.6,0,0,1-.6.629c-.438.025-.877.016-1.316.017q-2.019,0-4.037,0" transform="translate(1897.896 1498.57)" fill="#780c0f"/>
  </g>
</svg>

    )
}

export default Documentos;
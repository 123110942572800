import React from 'react';
import {Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Documentos from './pages/Documentos';
import Noticias from './pages/Noticias';
import NoticiasDetalhes from './pages/Noticias/NoticiasDetalhes';
import Cultura from './pages/Cultura';
import Galeria from './pages/Galeria';
import GaleriaDetalhes from './pages/Galeria/GaleriaDetalhes';
// import Plataforma from './pages/Plataforma';
import Links from './pages/Links';
import Contato from './pages/Contato';

export default function MainRoutes(){
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/documentos" element={<Documentos />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/noticias/detalhes/:id" element={<NoticiasDetalhes />} />
            <Route path="/cultura" element={<Cultura />} />
            <Route path="/galeria" element={<Galeria />} />
            <Route path="/galeria/detalhes/:id" element={<GaleriaDetalhes />} />
            {/* <Route path="/plataforma" element={<Plataforma />} /> */}
            <Route path="/links" element={<Links />} />
            <Route path="/contato" element={<Contato />} />
        </Routes>
    );
}
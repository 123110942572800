import React from 'react';

function WhatsLinks(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.061" height="39.061" viewBox="0 0 39.061 39.061"  fill="none" {...props}>
            <g id="Grupo_11" data-name="Grupo 11" transform="translate(0.5 0.5)">
                <path id="Caminho_63" data-name="Caminho 63" d="M157.191,39.061a19.536,19.536,0,0,1-7.6-37.526,19.535,19.535,0,0,1,15.2,35.991A19.409,19.409,0,0,1,157.191,39.061Zm0-38.061a18.53,18.53,0,1,0,18.531,18.531A18.551,18.551,0,0,0,157.191,1Z" transform="translate(-138.161 -0.5)" fill="#CD000E"/>
                <path id="Caminho_64" data-name="Caminho 64" d="M169.7,18.847a12.285,12.285,0,0,0-24.548-.224c-.006.178-.012.357-.012.535a12.1,12.1,0,0,0,1.76,6.3l-2.214,6.538,6.8-2.162A12.3,12.3,0,0,0,169.7,19.158c0-.1,0-.207-.006-.311M157.418,29.405a10.286,10.286,0,0,1-5.681-1.7l-3.968,1.265,1.288-3.807a10.14,10.14,0,0,1-1.967-6.009,9.389,9.389,0,0,1,.052-1,10.332,10.332,0,0,1,20.569.195c.023.265.035.529.035.8a10.3,10.3,0,0,1-10.328,10.247" transform="translate(-138.161 -0.5)" fill="#CD000E" fillRule="evenodd"/>
                <path id="Caminho_65" data-name="Caminho 65" d="M163.048,21.619c-.305-.149-1.783-.874-2.059-.972s-.477-.149-.679.15-.781.972-.954,1.167-.351.224-.655.075a8.13,8.13,0,0,1-2.421-1.484,8.935,8.935,0,0,1-1.674-2.064c-.172-.3-.017-.46.132-.61s.3-.351.455-.523l.109-.144a3.383,3.383,0,0,0,.19-.356.544.544,0,0,0-.023-.524c-.075-.149-.679-1.621-.932-2.219s-.5-.495-.678-.495-.374-.029-.575-.029a1.114,1.114,0,0,0-.806.374,3.348,3.348,0,0,0-1.052,2.5,3.838,3.838,0,0,0,.155,1.018,6.79,6.79,0,0,0,1.076,2.07,12.352,12.352,0,0,0,5.141,4.514c3.064,1.191,3.064.794,3.617.742a3,3,0,0,0,2.029-1.42,2.467,2.467,0,0,0,.179-1.421c-.075-.121-.276-.2-.575-.345" transform="translate(-138.161 -0.5)" fill="#CD000E" fillRule="evenodd"/>
            </g>
        </svg>
    )
}

export default WhatsLinks;
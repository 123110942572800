import styled from 'styled-components';

export const Fundo = styled.div`
    height: 100%;
    width: 100%;
    background-color: #FFFFFF; 
    padding: 70px 3%;
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
`;

export const Title = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #CD000E;
    margin-left: 8px;
`;

export const LinhaTitle = styled.div`
    display: flex;
    width: 100%;
`;

export const TitleTag = styled.p`
    font-size: 50px;
    font-weight: bold;
    color: #086087;
`;

export const SubTitle = styled.p`
    font-size: 20px;
    color: #B4B4B4;
    width: 80%;
    margin: auto;
`;
import * as S from './styles';
import CarouselCampanhas from '../CarouselCampanhas';
import DestaqueIcon from '../../assets/svg/DestaqueIcon';

export default function Parceiros() {
  return (
    <S.Fundo>
      <S.Center>
        <S.LinhaTitle>
          <DestaqueIcon />
          <S.Title>CAMPANHAS</S.Title>
        </S.LinhaTitle>
        <CarouselCampanhas />
        {/* <Row style={{ marginTop: "50px" }}>
          <Col xl={3} lg={6}>
            <Image src='/imagens/png/parceiro1.png' alt="" width="220" height="195" />
          </Col>
          <Col xl={3} lg={6}>
            <Image src='/imagens/png/parceiro2.png' alt="" width="220" height="195" />
          </Col>
          <Col xl={3} lg={6}>
            <Image src='/imagens/png/parceiro3.png' alt="" width="220" height="195" />
          </Col>
          <Col xl={3} lg={6}>
            <Image src='/imagens/png/parceiro1.png' alt="" width="220" height="195" />
          </Col>
        </Row> */}
      </S.Center>
    </S.Fundo>
  )
}

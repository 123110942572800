import React from 'react';

function InstaAzul(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.792" height="46.768" viewBox="0 0 10.396 23.384" fill="none" {...props}>
            <path id="Caminho_57" data-name="Caminho 57" d="M16.691,31.223h4.535V19.432h3.165l.337-3.948h-3.5V13.235c0-.931.181-1.3,1.048-1.3h2.454v-4.1h-3.14c-3.375,0-4.9,1.544-4.9,4.5v3.148H14.332v4h2.359Z" transform="translate(-14.332 -7.839)" fill="#780C0F"/>
        </svg>
    )
}

export default InstaAzul;